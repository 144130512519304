import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import Badge from '@material-ui/core/Badge';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import MicIcon from '@material-ui/icons/Mic';
import { useStyles_json } from 'assets/jss/all_styles';
import { IconButton } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { IconProofType_json_1 } from 'assets/jss/all_styles';
import { makeStyles } from '@material-ui/styles';

const TailCommitmentLine = props => {
  const { history } = props;
  const classes = makeStyles(useStyles_json);

  const IconProofType = props => {
    const classes = makeStyles(useStyles_json);

    switch (props.proof_type) {
      case 'Image':
        return (
          <Box display="flex" style={IconProofType_json_1}>
            <ImageOutlinedIcon
              className={classes.statusIcone}
              fontSize="small"
            />
          </Box>
        );
      case 'Text':
        return (
          <Box display="flex" style={IconProofType_json_1}>
            <AssignmentOutlinedIcon
              className={classes.statusIcone}
              fontSize="small"
            />
          </Box> // classes={props.classes}
        );
      // return 'Text'
      // return <AssignmentOutlinedIcon fontSize="small" />
      case 'Audio':
        return (
          <Box display="flex" style={IconProofType_json_1}>
            <MicIcon className={classes.statusIcone} fontSize="small" />
          </Box> //classes={props.classes}
        );
      // return 'Audio'
      // return <MicIcon fontSize="small"  />
      case 'Other':
        return (
          <Box display="flex" style={IconProofType_json_1}>
            <FileCopyOutlinedIcon
              className={classes.statusIcone}
              fontSize="small"
            />
          </Box> //classes={props.classes}
        );
      // return 'Other'
      // return <FileCopyOutlinedIcon fontSize="small"  />
      default:
        return (
          <Box display="flex" style={IconProofType_json_1}>
            <FileCopyOutlinedIcon
              className={classes.statusIcone}
              fontSize="small"
            />
          </Box> //classes={props.classes}
        );
      // return 'Other'
      // return <FileCopyOutlinedIcon fontSize="small" />
    }
  };

  return (
    <Box alignSelf="center" display="flex" flexGrow={0} p={0}>
      {props.commitment_of_user.in_progress === 'true' && (
        <Box alignSelf="center" display="flex" flexGrow={0} p={1}>
          <IconButton
            // color="00a82d"
            // aria-label="Give a proof for this commitment"
            className={classes.newhover}
            onClick={() =>
              history.push({
                pathname: '/front/append_proof',
                value: {
                  user: props.user,
                  id: props.id
                }
              })
            }
            // style={{border: '1px solid rgba(230, 230, 230, 1)',}}
          >
            <Tooltip aria-label="add" title="Give a proof for this commitment">
              <Badge
                badgeContent={<IconProofType proof_type={props.proof_type} />}
                showZero>
                <AddCircleIcon fontSize="medium" />
              </Badge>
            </Tooltip>
          </IconButton>
        </Box>
      )}

      <Box alignSelf="center" display="flex" flexGrow={0} p={1}>
        <IconButton
          // color="00a82d"
          aria-label="Show commitment details"
          className={classes.newhover}
          // style={{border: '1px solid rgba(230, 230, 230, 1)',}}
          onClick={() => {
            localStorage.setItem(
              'commitment',
              JSON.stringify(props.commitment_of_user)
            );
            history.push({
              pathname: '/front/show_commitment'
            });
          }}>
          <Tooltip
            aria-label="Show commitment details"
            title="Show commitment details">
            <ListAltOutlinedIcon proof_type={props.proof_type} />
          </Tooltip>
        </IconButton>
      </Box>
    </Box>
  );
};
TailCommitmentLine.propTypes = {
  className: PropTypes.string
};

export default TailCommitmentLine;
