import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import { Avatar } from '@material-ui/core';

import { GET_SERVER } from 'common/get';
import { POST_SERVER } from 'common/post';
import { path } from 'common/path';

import {
AccountDetails_1,
AccountDetails_2,
AccountDetails_3
} from 'assets/jss/all_styles';

import basket from '../../../../assets/img/basket_sunflore_illu_cut.jpg';
import default_avatar from '../../../../assets/img/avatars/default.png';


import {
BigArea,
SmallArea,
TextFieldShow,
IconeAndTextField
} from 'components';

const styles = AccountDetails_1;

class AccountDetails extends Component {
state = {
first_name: '',
last_name: '',
avatar_url: '',
email: '',
point: null,
nbr_commitment_success: null,
nbr_commitment_failed: null,
nbr_commitment_in_progress: null,
nbr_commitment_shared: null
};

componentDidMount() {
this.handlProfileData();
}
handlProfileData = async () => {
try {
  let path_profile_data = path + 'back/profile_data';
  const var_from_GET_SERVER = await GET_SERVER(path_profile_data);
  const profile_user_data = var_from_GET_SERVER['profile_data'];

  this.setState({
    first_name: profile_user_data.first_name,
    last_name: profile_user_data.last_name,
    avatar_url: profile_user_data.avatar_url,
    email: profile_user_data.email,
    point: profile_user_data.point,
    nbr_commitment_success: profile_user_data.nbr_commitment_success,
    nbr_commitment_failed: profile_user_data.nbr_commitment_failed,
    nbr_commitment_in_progress:
      profile_user_data.nbr_commitment_in_progress,
    nbr_commitment_shared: profile_user_data.nbr_commitment_shared
  });
} catch (error) {}
};
handleProfileChange = async () => {
try {
  const { values } = { values: this.state };

  this.setState({ isLoading: true });

  let path_changer_profil_data = path + 'back/changer_profil_data';
  const var_from_POST_SERVER = await POST_SERVER(
    path_changer_profil_data,
    values
  );
  const profile_data_a_ete_changer =
    var_from_POST_SERVER['profile_data_a_ete_changer'];

  this.props.history.push('/front/home');
} catch (error) {
  this.setState({
    isLoading: false,
    serviceError: error
  });
}
};
handleChange = e => {
const value = e.target.value;
// console.log('name :' + e.target.name);
this.setState({
  [e.target.name]: value
});
};

render() {
const { classes, className, ...rest } = this.props;
const {
  first_name,
  last_name,
  email,
  point,
  avatar_url,
  nbr_commitment_success,
  nbr_commitment_failed,
  nbr_commitment_in_progress,
  nbr_commitment_shared
} = this.state;
console.log("avatar_url : "+avatar_url)
console.log(avatar_url)

return (
  <Box p={0} sx={{ minWidth: '50px' }}>
    <BigArea alias={'User'} classes={classes}>
      {avatar_url !== 'None' ? (
        <SmallArea alias={'Avatar'} classes={classes}>
          <Box alignSelf="center" display="flex" p={2}>
            <Card style={AccountDetails_2}>
              <CardMedia
              className={classes.media}
              src={avatar_url}
              title="Avatar image"
              />
            </Card>
          </Box>
        </SmallArea>
      ) : (
        <SmallArea alias={'Avatar'} classes={classes}>
          <Box alignSelf="center" display="flex" p={0}>
            <Card style={AccountDetails_2}>
              <CardMedia
              className={classes.media}
              image={default_avatar}
              title="Avatar image"
              /> 
            </Card>
          </Box>
        </SmallArea>
      )
      }
      <SmallArea alias={'Email'} classes={classes}>
        <TextFieldShow contenu={email} />
      </SmallArea>
      {first_name !== 'None' && (
        <SmallArea alias={'First name'} classes={classes}>
          <TextFieldShow contenu={first_name} />
        </SmallArea>
      )}
      {last_name !== 'None' && (
        <SmallArea alias={'Last name'} classes={classes}>
          <TextFieldShow contenu={last_name} />
        </SmallArea>
      )}
      <SmallArea alias={'Sunflowers Basket'} classes={classes}>
        <IconeAndTextField
          icone={
            <img
              alt="Sunwill flower"
              width="25px"
              src={basket}
              style={AccountDetails_3}
            />
          }
          text={Math.floor(point)}
        />
      </SmallArea>
    </BigArea>

    <BigArea alias={'Commitment'} classes={classes}>
      <SmallArea alias={'Success'} classes={classes}>
        <TextFieldShow contenu={nbr_commitment_success} />
      </SmallArea>
      <SmallArea alias={'In progress'} classes={classes}>
        <TextFieldShow contenu={nbr_commitment_in_progress} />
      </SmallArea>
      <SmallArea alias={'Failed'} classes={classes}>
        <TextFieldShow contenu={nbr_commitment_failed} />
      </SmallArea>
      <SmallArea alias={'Shared in social media'} classes={classes}>
        <TextFieldShow contenu={nbr_commitment_shared} />
      </SmallArea>
    </BigArea>
  </Box>
);
}
}

AccountDetails.propTypes = {
className: PropTypes.string,
classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AccountDetails);
