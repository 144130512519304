/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import { List, ListItem, Button } from '@material-ui/core';

import Box from '@material-ui/core/Box';

import {
  SidebarNav_theme_json,
  SidebarNav_json,
  SidebarNav_json_2
} from 'assets/jss/all_styles';

const useStyles = makeStyles(SidebarNav_theme_json);

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const SidebarNav = props => {
  const { pages, className, open, ...rest } = props;

  const classes = useStyles();

  return (
    <List {...rest} className={clsx(classes.root, className)}>
      {pages.map(page => (
        <ListItem className={classes.item} disableGutters key={page.title}>
          <Button
            activeClassName={classes.active}
            className={classes.button}
            component={CustomRouterLink}
            to={page.href}>
            <div className={classes.icon}>{page.icon}</div>

            {open ? (
              <Box style={SidebarNav_json}>{page.title}</Box>
            ) : (
              <Box style={SidebarNav_json_2}></Box>
            )}
          </Button>
        </ListItem>
      ))}
    </List>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired
};

export default SidebarNav;
