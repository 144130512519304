import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { Divider } from '@material-ui/core';
import { DynamicIconTheme, TailCommitmentLine, CorpsCommitmentLine } from './';
import {
  useStyles_json,
  with_commitment_line,
  padding_commitment_line
} from 'assets/jss/all_styles';
import { makeStyles } from '@material-ui/styles';


const CommitmentLine = props => {
  const { history } = props;
  const classes = makeStyles(useStyles_json);

  // const [formState, setFormState] = useState({
  // 	commitment_of_user: objectMap(props.commitment_of_user)
  // });

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };


  return (
    <div style={with_commitment_line}>
      <Box display="flex" p={2} style={padding_commitment_line} width="100%" flexWrap="wrap" >
        {/* display="flex" p={0}  width="100%" */}
        <DynamicIconTheme
          success={props.commitment_of_user.success}
          in_progress={props.commitment_of_user.in_progress}
          failed={props.commitment_of_user.failed}
          history={history}
          theme={props.commitment_of_user.theme}
        />
        <CorpsCommitmentLine
          id={props.commitment_of_user.id}
          user={props.commitment_of_user.user}
          use_sunflowers={props.commitment_of_user.use_sunflowers}
          use_social_share={props.commitment_of_user.use_social_share}
          title={props.commitment_of_user.title}
          success={props.commitment_of_user.success}
          in_progress={props.commitment_of_user.in_progress}
          failed={props.commitment_of_user.failed}
          commitment_of_user={props.commitment_of_user}
          end_time={props.commitment_of_user.end_time}
          proof_type={props.commitment_of_user.proof_type}
        />
        <TailCommitmentLine
          commitment_of_user={props.commitment_of_user}
          history={history}
          id={props.commitment_of_user.id}
          proof_type={props.commitment_of_user.proof_type}
          user={props.commitment_of_user.user}
        />
      </Box>
      <Divider className={classes.divider} />
    </div>
  );
};

CommitmentLine.propTypes = {
  className: PropTypes.string
};

export default CommitmentLine;
