import PropTypes from 'prop-types';

import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography as MuiTypography } from '@material-ui/core';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import GetAppIcon from '@material-ui/icons/GetApp';
import Box from '@material-ui/core/Box';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import VideocamIcon from '@material-ui/icons/Videocam';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import Tooltip from '@material-ui/core/Tooltip';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import EventNoteIcon from '@material-ui/icons/EventNote';

// import { GET_SERVER, POST_SERVER, path } from '../../common';
import { GET_SERVER } from 'common/get';
import { POST_SERVER } from 'common/post';
import { path } from 'common/path';

import { Button } from '@material-ui/core';
import { admin_style } from 'assets/jss/all_styles';
import { Typography } from '@material-ui/core';

import {
  corps_commitment_line_font,
  corps_commitment_line_vertcial,
  corps_commitment_line_typography,
  corps_commitment_line_pad
} from 'assets/jss/all_styles';

const LineBlock = props => {
  const { order_of_proof, history } = props;

  return (
    <Box alignSelf="center" flexGrow={1} flexShrink={1} p={1} flexWrap="wrap">
      <Box display="flex" flexGrow={1} p={0}>
        <Box
          display="flex"
          flexGrow={1}
          p={0.5}
          style={{ paddingTop: '0px', paddingBottom: '0px' }}>
          <Typography style={corps_commitment_line_typography} variant="h5">
            <Box
              m={1}
              component="div"
              display="inline"
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
              }}
            />
            {'Number : ' +
              props.formState.formatted_proofs[order_of_proof]['id']}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" flexGrow={1} p={0}>
        <Box
          alignSelf="center"
          flexGrow={0}
          flexShrink={1}
          p={0.5}
          style={corps_commitment_line_pad}>
          <Box m={1} component="div" display="inline" />

          <PermMediaIcon fontSize="small" />
          <Box m={1} component="div" display="inline">
            {props.formState.formatted_proofs[order_of_proof]['proof_type']}
          </Box>
          <Box m={0.5} component="div" display="inline" />

          <PersonOutlineIcon fontSize="small" />
          <Box m={1} component="div" display="inline">
            {props.formState.formatted_proofs[order_of_proof]['email']}
          </Box>
          <Box m={0.5} component="div" display="inline" />

          <DoneOutlineIcon fontSize="small" />
          <Box m={1} component="div" display="inline">
            {props.formState.formatted_proofs[order_of_proof]['commitment']}
          </Box>
          <Box m={0.5} component="div" display="inline" />
        </Box>
        <Box display="flex" flexGrow={1} justifyContent="flex-end" p={0} />
      </Box>
    </Box>

  );
};

LineBlock.propTypes = {
  className: PropTypes.string
};

export default LineBlock;
