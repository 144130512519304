import React from 'react';
import _ from 'underscore';
import {
  Grid,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { styles } from 'assets/jss/all_styles';

const BigArea = props => {
  return (
    <Grid container item md={12} xs={12}>
      <Grid container item md={3} xs={12}>
        <Box p={0.5} className={styles.aliasleft}>
          {props.alias}
        </Box>
      </Grid>
      <Grid container item md={9} xs={12}>
        {props.children}
      </Grid>
    </Grid>
  );
};

export default BigArea
