import React from 'react';
import _ from 'underscore';

import Box from '@material-ui/core/Box';

const ImageContainer = props => {
  return (
    <Box>
      <Box p={5}>
        <img src={props.path_or_url_of_image} alt="" style={{ width: '100%' }} />
      </Box>
    </Box> 
  );
};
 
export default ImageContainer;
