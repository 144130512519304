import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import sunflower from 'assets/img/icons8-sunflower-50.png';
import { useStyles_json } from 'assets/jss/all_styles';
import FacebookIcon from '@material-ui/icons/Facebook';
import Tooltip from '@material-ui/core/Tooltip';
import TermFormat from 'common/TermFormat';
import {
  corps_commitment_line_font,
  corps_commitment_line_vertcial,
  corps_commitment_line_typography,
  corps_commitment_line_pad
} from 'assets/jss/all_styles';

import { makeStyles } from '@material-ui/styles';

import UploadFileIcon from '@mui/icons-material/UploadFile';

import { POST_SERVER } from 'common/post';
import { path } from 'common/path';

const CorpsCommitmentLine = props => {


  const handleCommitmentHasProof = async (commitment_of_user_id) => {
    const commitment = {
      id: commitment_of_user_id
    };

    let commitment_has_proof_path = path + 'back/commitment_has_proof';

    const var_from_POST_SERVER = await POST_SERVER(
      commitment_has_proof_path,
      commitment
    );

    const commitment_has_proof = var_from_POST_SERVER['commitment_has_proof'];

    setcommitment_has_proof(commitment_has_proof)

    return commitment_has_proof;
  };


  const [commitment_has_proof, setcommitment_has_proof] = React.useState(false);
  const classes = makeStyles(useStyles_json);

  const TextStatus = props => {

    if (props.success === 'true') {
      return (
        <Typography color="textSecondary" style={corps_commitment_line_font}>
          {'Ended with success'}
        </Typography>
      );
    } else if (props.in_progress === 'true') {
      return (
        <Typography color="textSecondary" style={corps_commitment_line_font}>
          {'In progress'}
        </Typography>
      );
    } else if (props.failed === 'true') {
      return (
        <Typography color="textSecondary" style={corps_commitment_line_font}>
          {'Ended'}
        </Typography>
      );
    } else {
      return (
        <Typography color="textSecondary" style={corps_commitment_line_font}>
          {'In progress'}
        </Typography>
      );
    }
  };

  const IconUsePoints = props => {
    // console.log('props.use_sunflowers');
    // console.log('props.use_sunflowers.type_of()');
    // console.log(typeof props.use_sunflowers);
    return (
      <Box display="flex" flexGrow={0} style={corps_commitment_line_vertcial}>
        {props.use_sunflowers==='true' ?
          <Tooltip
            aria-label="Sunflowers are used"
            title="Sunflowers are used">
            <img
              alt="Sunwill flower"
              src={sunflower}
              style={corps_commitment_line_vertcial}
              width="25px"
            />
          </Tooltip>
         : (
          <Box />
        )}
      </Box>
    );
  };

  const IconUseSocial = props => {
    // console.log('props.use_social_share');
    // console.log('props.use_social_share.type_of()');
    // console.log(typeof props.use_social_share);
    return (
      <Box display="flex" flexGrow={0} style={corps_commitment_line_vertcial}>
        {props.use_social_share==='true' ? 
        <Tooltip
          aria-label="Commitment was shared"
          title="Commitment was shared"
        >
          <FacebookIcon />
        </Tooltip> : <Box />}
      </Box>
    );
  };

  const IconEvidence = props => {

    handleCommitmentHasProof(props.commitment_of_user.id)

    return (
      <Box display="flex" flexGrow={0} style={corps_commitment_line_vertcial}>
        {commitment_has_proof ? 
          <Tooltip
            aria-label="Evendence was sent"
            title="Evendence was sent">
            <UploadFileIcon />
          </Tooltip> : <Box />}
      </Box>
    );
  };

  return (
    <Box alignSelf="center" flexGrow={1} flexShrink={1} p={1}>
      <Box display="flex" flexGrow={1} p={0}>
        <Box
          display="flex"
          flexGrow={1}
          p={0.5}
          style={{ paddingTop: '0px', paddingBottom: '0px' }}>
          <Typography style={corps_commitment_line_typography} variant="h5">
            {props.title}
            <IconUsePoints use_sunflowers={props.use_sunflowers} />
            <IconUseSocial use_social_share={props.use_social_share} />
            <IconEvidence 
            commitment_of_user={props.commitment_of_user}
            // handleCommitmentHasProof={props.handleCommitmentHasProof}
             />
          </Typography>
        </Box>
      </Box>
      <Box display="flex" flexGrow={1} p={0}>
        <Box
          alignSelf="center"
          flexGrow={0}
          flexShrink={1}
          p={0.5}
          style={corps_commitment_line_pad}>
          {/* <Typography color="textSecondary" style={{ fontSize: 14 }}>  */}{' '}
          {/* <TermFormat end_time={props.end_time}/> */}
          {TermFormat(props.end_time)}
          {/* </Typography> */}
          {/* <Typography style={{ fontSize: 14 }} color="textSecondary" >{"End time :   " + props.end_time + " "}</Typography> */}
        </Box>
        <Box display="flex" flexGrow={1} justifyContent="flex-end" p={0} />
      </Box>
    </Box>
  );
};

CorpsCommitmentLine.propTypes = {
  className: PropTypes.string
};

export default CorpsCommitmentLine;
