import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';

import styles from 'assets/jss/material-kit-react/views/landingPage.js';

import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import { ImageContainer } from 'components';

const ScreenShowImage = props => {
    let { params }  = props
    const useStyles = makeStyles(styles);

    const classes = useStyles();
  return (
    <div className={classes.container}>
      <GridContainer alignItems="center">
        <GridItem md={12} sm={12} xs={12}>
          <ImageContainer
            className={classes.mainimage}
            path_or_url_of_image={decodeURIComponent(params.url)}
          />
        </GridItem>
        <Grid md={12} sm={12} xs={12}>
          <Box display="flex" justifyContent="center" m={1} p={1}>
            <Box p={1}>
              <Box display="flex" justifyContent="center">
                <h1 className={classes.title}>Build the things you want</h1>
              </Box>
              <Box display="flex" justifyContent="center">
                <h4>
                  Method helps you accomplish the things that matters for you.
                  <br />
                  <br />
                </h4>
                <br />
                <br />
                <br />
              </Box>
              <Box display="flex" justifyContent="center">
                <p>
                  Chose what you want to realize and bind your self to achieve
                  it, beware that not following your own commitment have
                  consequences : the ones you had chosen
                </p>
              </Box>
              <br />

              <Box display="flex" justifyContent="center">
                <Link
                  className={classes.navLink}
                  color="transparent"
                  to="/front/sign_in">
                  <Button color="success" rel="noopener noreferrer" size="sm">
                    <i className="fas fa-play" />
                    Sign up for free
                  </Button>
                </Link>
              </Box>
            </Box>
          </Box>
        </Grid>
      </GridContainer>
    </div>
  );
};


ScreenShowImage.propTypes = {
    className: PropTypes.string
  };
  
  export default ScreenShowImage;
  