import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Avatar } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import {
  Profile_json_2,
  Profile_json_3,
  Profile_json_4,
  Profile_json_5
} from 'assets/jss/all_styles';


const ButtonToProfil = props => {
  let { classes, open, email, avatar} = props
  return (
    <Link to="/front/profile">
      <Box p={0} display="flex" alignSelf="center" flexGrow={1}>
        <Box
          p={1}
          display="flex"
          flexGrow={0}
          style={Profile_json_2}
          alignSelf="center"
          justifyContent="center">
          <Avatar
            alt="Person"
            className={classes.avatar}
            // component={Link}
            src={avatar}
            to="/front/profile"
            style={Profile_json_3}
          />
        </Box>

        {open ? (
          <Box
            p={1}
            display="flex"
            alignSelf="center"
            flexGrow={1}
            style={Profile_json_4}>
            {email}
          </Box>
        ) : (
          <Box
            p={0}
            display="flex"
            alignSelf="center"
            flexGrow={1}
            style={Profile_json_5}></Box>
        )}
      </Box>
    </Link>
  );
};


ButtonToProfil.propTypes = {
    className: PropTypes.string
  };
  
  export default ButtonToProfil;
  