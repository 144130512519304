import _ from 'underscore';

export default function TermFormat(end_time) {
  const present = new Date();
  const term = new Date(end_time);
  console.log("present : ")
  console.log(present)
  console.log("term : ")
  console.log(term)

  const difference = new Date(term.getTime() - present.getTime());



  const years = difference.getUTCFullYear() - 1970; // Gives difference as year
  const months = difference.getUTCMonth(); // Gives month count of difference
  const weeks = Math.floor((difference.getUTCDate() - 1) / 7); // Gives weeks count of difference
  const days = difference.getUTCDate() - 1 - weeks * 7; // Gives day count of difference
  const hours = difference.getUTCHours(); // Gives hours count of difference
  const minutes = difference.getUTCMinutes(); // Gives minutes count of difference
  const seconds = difference.getUTCSeconds(); // Gives minutes count of difference
 
  
  console.log(
    'remaining time = ' +
      years +
      ' years, ' +
      months +
      ' months, ' +
      weeks +
      ' weeks, ' +
      days +
      ' days, ' +
      hours +
      ' hours, ' +
      minutes +
      ' minutes, ' +
      seconds +
      ' seconds'
  );

  if (
    years < 0 ||
    months < 0 ||
    weeks < 0 ||
    days < 0 ||
    hours < 0 ||
    minutes < 0 ||
    seconds < 0
  ) {
    return '';
  } else if (years >= 3) {
    return years + ' years and  ' + months + ' months left';
  } else if (years > 0 && weeks > 0) {
    console.log('years>=0');
    console.log(years);
    console.log(typeof years);
    return (
      years + ' years and  ' + months + ' months and ' + weeks + ' weeks left'
    );
  } else if (years > 0) {
    console.log('years>=0');
    console.log(years);
    console.log(typeof years);
    return years + ' years and ' + months + ' months left';
  } else if (months > 0) {
    return months + ' months and ' + weeks + ' weeks left';
  } else if (weeks > 0) {
    return weeks + ' weeks and ' + days + ' days left';
  } else if (days > 0) {
    return days + ' days and ' + hours + ' hours left';
  } else if (hours > 0) {
    return hours + ' hours and ' + minutes + ' minutes left';
  } else if (minutes > 0) {
    return minutes + ' minutes and ' + seconds + ' seconds left';
  } else if (seconds > 0) {
    return seconds + ' seconds left !';
  } else {
    return '';
  }
}
