import React from 'react';
import PropTypes from 'prop-types';

import MicIcon from '@material-ui/icons/Mic';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';

const IconProofType = props => {
  switch (props.proof_type) {
    case 'Image':
      return <ImageOutlinedIcon fontSize="small" />; //classes={props.classes}
    case 'Text':
      return <AssignmentOutlinedIcon fontSize="small" />;
    case 'Audio':
      return <MicIcon fontSize="small" />;
    case 'Other':
      return <FileCopyOutlinedIcon fontSize="small" />;
    default:
      return <FileCopyOutlinedIcon fontSize="small" />;
  }
};

IconProofType.propTypes = {
  className: PropTypes.string
};

export default IconProofType;
