import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';

import {
  TextFieldShow
} from 'components';

import is_empty from 'common/is_empty';
import {
  ShowCommitmentFile_1,
  ShowCommitmentFile_2
} from 'assets/jss/all_styles';
import { 
    DownloadFile,
  } from '../../components';

const ShowProof = props => {
  if (!is_empty(props.proof)) {
    if (props.proof_type === 'Image') {
      return (
        <Box p={1}>
          <Card style={ShowCommitmentFile_1}>
            <CardMedia
              style={ShowCommitmentFile_2}
              image={props.proof.file_url}
              title="Proof Image"
            />
          </Card>
        </Box>
      );
    } else {
      return (
        <DownloadFile
          url={props.proof.file_url}
          filename={props.proof.file_filename}
        />
      );
    }
  } else {
    return <TextFieldShow value={'No proof was appended to this commitment'} />;
  }
};

ShowProof.propTypes = {
  className: PropTypes.string
};

export default ShowProof;
