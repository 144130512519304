import React from 'react';

import PropTypes from 'prop-types';
import { Grid} from '@material-ui/core';
import Select from '@material-ui/core/Select';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';

const FilterByStatus = props => {
    return (
      <Grid container item xs={12} sm={12}>
        <Grid container item xs={9} sm={11}></Grid>
        <Grid container item xs={3} sm={1} justify="flex-end">
          <Box m={1}>
            <Select
              value={props.formState.state_of_proof_as_string}
              onChange={event => props.handleChange(event.target.value)}
              variant="outlined">
              <MenuItem value={'all'}>{'all'}</MenuItem>
              <MenuItem value={'accept'}>{'accepted'}</MenuItem>
              <MenuItem value={'decline'}>{'declined'}</MenuItem>
              <MenuItem value={'in_progress'}>{'in process'}</MenuItem>
            </Select>
          </Box>
        </Grid>
      </Grid>
    );
  };

  FilterByStatus.propTypes = {
    className: PropTypes.string
  };
  
  export default FilterByStatus;