import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

import { TipsBar_json_1 } from 'assets/jss/all_styles';

const TipsBar = props => {
return <Box
alignSelf="center"
display="flex"
flexGrow={1}
p={1}
style={TipsBar_json_1}>
Tips : Organize your taskes, it will lighten you mind and make it easier
to do them.
</Box>;

}

TipsBar.propTypes = {
  className: PropTypes.string
};

export default TipsBar;
