import PropTypes from 'prop-types';

import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography as MuiTypography } from '@material-ui/core';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import GetAppIcon from '@material-ui/icons/GetApp';
import Box from '@material-ui/core/Box';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import VideocamIcon from '@material-ui/icons/Videocam';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import Tooltip from '@material-ui/core/Tooltip';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import EventNoteIcon from '@material-ui/icons/EventNote';

// import { GET_SERVER, POST_SERVER, path } from '../../common';
import { GET_SERVER } from 'common/get';
import { POST_SERVER } from 'common/post';
import { path } from 'common/path';

import { Button } from '@material-ui/core';
import { admin_style_json } from 'assets/jss/all_styles';
import { AvatarBlock, LineBlock, PauseBlock, ButonBlock } from './';
import {
  with_commitment_line,
  padding_commitment_line
} from 'assets/jss/all_styles';
import { Divider } from '@material-ui/core';


const useStyles = makeStyles(admin_style_json);

const LineProofAdmin = props => {
  const {
    order_of_proof,
    formState,
    setFormState,
    handleDownloadFile,
    colorPreuve,
    iconPreuve,
    handleAcceptProof,
    handleDeclineProof,
    history
  } = props;

  const classes = useStyles();

  return (
    <div style={with_commitment_line}>
      <Box display="flex" p={2} style={padding_commitment_line} width="100%" sx={{ flexWrap: 'wrap' }}>
        <AvatarBlock
          order_of_proof={order_of_proof}
          formState={formState}
          setFormState={setFormState}
          handleDownloadFile={handleDownloadFile}
          colorPreuve={colorPreuve}
          iconPreuve={iconPreuve}
          handleAcceptProof={handleAcceptProof}
          handleDeclineProof={handleDeclineProof}
          classes={classes}
        />
        <LineBlock
          order_of_proof={order_of_proof}
          formState={formState}
          setFormState={setFormState}
          handleDownloadFile={handleDownloadFile}
          colorPreuve={colorPreuve}
          iconPreuve={iconPreuve}
          handleAcceptProof={handleAcceptProof}
          handleDeclineProof={handleDeclineProof}
          classes={classes}
        />
        <ButonBlock
          order_of_proof={order_of_proof}
          formState={formState}
          setFormState={setFormState}
          handleDownloadFile={handleDownloadFile}
          colorPreuve={colorPreuve}
          iconPreuve={iconPreuve}
          handleAcceptProof={handleAcceptProof}
          handleDeclineProof={handleDeclineProof}
          classes={classes}
          history={history}
        />
      </Box>
      <Divider className={classes.divider} />
    </div>

    // <List className={classes.root}>
    //   <Paper>
    //     <Grid container item xs={12} sm={12}>
    //       <ListItem>

    //         <AvatarBlock

    //          order_of_proof={order_of_proof}
    //          formState={formState}
    //          setFormState={setFormState}
    //          handleDownloadFile={handleDownloadFile}
    //          colorPreuve={colorPreuve}
    //          iconPreuve={iconPreuve}
    //          handleAcceptProof={handleAcceptProof}
    //          handleDeclineProof={handleDeclineProof}

    //          classes={classes}
    //         />
    //         <LineBlock
    //                      order_of_proof={order_of_proof}
    //                      formState={formState}
    //                      setFormState={setFormState}
    //                      handleDownloadFile={handleDownloadFile}
    //                      colorPreuve={colorPreuve}
    //                      iconPreuve={iconPreuve}
    //                      handleAcceptProof={handleAcceptProof}
    //                      handleDeclineProof={handleDeclineProof}

    //                      classes={classes}
    //         />
    //         <PauseBlock/>
    //         <ButonBlock
    //                      order_of_proof={order_of_proof}
    //                      formState={formState}
    //                      setFormState={setFormState}
    //                      handleDownloadFile={handleDownloadFile}
    //                      colorPreuve={colorPreuve}
    //                      iconPreuve={iconPreuve}
    //                      handleAcceptProof={handleAcceptProof}
    //                      handleDeclineProof={handleDeclineProof}

    //                      classes={classes}
    //         />

    //       </ListItem>
    //     </Grid>
    //   </Paper>
    // </List>
  );
};

LineProofAdmin.propTypes = {
  className: PropTypes.string
};

export default LineProofAdmin;
