// export async function GET_SERVER(path) {
//   return fetch(path, {
//     method: 'GET',
//     // credentials: 'include',
//     // hedears: 'Access-Control-Allow-Origin',
//   })
//     .then(r => r.json())
//     .then(j => {
//       return j;
//     })
//     .catch(err => console.log(err));
// }


export async function GET_SERVER(path) {
  return fetch(path, {
    method: 'GET',
    credentials: 'include',
    // headers : { 
    //   'Access-Control-Allow-Origin' : "*",
    //   'Content-Type': 'application/json',
    //   'Accept': 'application/json'
    //  },
  })
    .then(r => {
      console.log("response : ");
      console.log(r);
      // console.log("response as json: ");
      // console.log(r.json());
      return r.json()})
    .then(j => {
      console.log(j);
      return j;
    })
    .catch(err => console.log(err));
}
