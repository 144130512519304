import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { BigArea} from 'components';
import { useStyles_appendproof_json,confirm_proof } from 'assets/jss/all_styles';
import { create_commitment_block_use_style } from 'assets/jss/all_styles';

const button_validate_use_style = makeStyles(create_commitment_block_use_style)



const ConfirmProof = props => {
    const classes = makeStyles(useStyles_appendproof_json);
    const classes_2 = makeStyles(button_validate_use_style)
    return (
      <BigArea alias={'Validate'} classes={classes}>
        <Button
          className={classes_2.create_commitment_block}
          // className={classes.button}
          color="primary"
          disabled={!props.formState.isValid}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          style={confirm_proof}>
          {props.button_text}
        </Button>
      </BigArea>
    );
  };

  ConfirmProof.propTypes = {
    className: PropTypes.string
  };
  
  export default ConfirmProof;