import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';

import Box from '@material-ui/core/Box';
import { Divider } from '@material-ui/core';

import { DividerInBox_json } from 'assets/jss/all_styles';

const DividerInBox = props => {
  return (
    <Box style={DividerInBox_json}>
      <Divider />
    </Box>
  );
};

DividerInBox.propTypes = {
  className: PropTypes.string
};

export default DividerInBox;
