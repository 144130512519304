import React from 'react';
import PropTypes from 'prop-types';

import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';
import SportsBaseballIcon from '@material-ui/icons/SportsBaseball';
import PhoneRoundedIcon from '@material-ui/icons/PhoneRounded';

const IconCommitment = props => {
  switch (props.theme) {
    case 'Other':
      return <HelpOutlineIcon fontSize="small" />; //classes={props.classes} <HelpOutlineIcon fontSize="small" />
    case 'Education':
      return <MenuBookIcon fontSize="small" />;
    case 'Sport':
      return <SportsBaseballIcon fontSize="small" />;
    case 'Work':
      return <WorkOutlineIcon fontSize="small" />;
    case 'Life':
      return <EmojiObjectsIcon fontSize="small" />;
    case 'Contact':
      return <PhoneRoundedIcon fontSize="small" />;
    case 'Game':
      return <SportsEsportsIcon fontSize="small" />;
    default:
      return <HelpOutlineIcon fontSize="small" />;
  }
};

IconCommitment.propTypes = {
  className: PropTypes.string
};

export default IconCommitment;
