import React from 'react';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';

import Header from 'components/Header/Header.js';
import Footer from 'components/Footer/Footer.js';
import GridItem from 'components/Grid/GridItem.js';
import HeaderLinks from 'components/Header/HeaderLinks.js';
import { ProductSection } from './components';
import { TeamSection }from './components';
import { ScreenIntroduction } from './components';

import styles from 'assets/jss/material-kit-react/views/landingPage.js';

import mac_screen_full_no_background_crop_web from 'assets/img/mac_screen_full_no_background_crop_web.png';

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  console.log("process.env.REACT_APP_PATH_OF_BACK_END :::::::")

  console.log(process.env.REACT_APP_PATH_OF_BACK_END)
  return (
    <div>
      <Header
        brand="Accomplish what you want"
        changeColorOnScroll={{
          height: 400,
          color: '#ffffff'
        }}
        fixed
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      <ScreenIntroduction
        classes={classes}
        title="Build the things you want"
        text_introduction="Chose what you want to realize and bind your self to achieve it,
          beware that not following your own commitment have consequences :
          the ones you had chosen"
        subtitle="Method helps you accomplish the things that matters for you."
        button_text="Sign up for free"
        screen_image={mac_screen_full_no_background_crop_web}
      />
      <GridItem item md={12} sm={12} xs={12}>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <TeamSection />
          <ProductSection />
        </div>
      </GridItem>
      <Footer />
    </div>
  );
}
