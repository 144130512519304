import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import DoneIcon from '@material-ui/icons/Done';
import Badge from '@material-ui/core/Badge';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';

import { useStyles_json } from 'assets/jss/all_styles';
import { dynamic_icon_theme } from 'assets/jss/all_styles';

import { IconCommitment } from '../';
import { makeStyles } from '@material-ui/styles';

const DynamicIconTheme = props => {
  const classes = makeStyles(useStyles_json);

  const colorCommitment = () => {
    if (props.failed === 'true') {
      return classes.red;
    }
    if (props.success === 'true') {
      return classes.green;
    }
    if (props.in_progress === 'true') {
      return classes.blue;
    }
  };

  const IconStatus = props => {
    const classes = makeStyles(useStyles_json);
    console.log('status success' + props.success);
    console.log('status in_progress' + props.in_progress);
    console.log('status failed' + props.failed);
    if (props.success === 'true') {
      return (
        <DoneIcon
          className={classes.statusIcone}
          fontSize="small"
          style={dynamic_icon_theme}
        />
      );
    } else if (props.in_progress === 'true') {
      return (
        <AutorenewIcon
          className={classes.statusIcone}
          fontSize="small"
          style={dynamic_icon_theme}
        />
      );
    } else if (props.failed === 'true') {
      return (
        <ClearRoundedIcon
          className={classes.statusIcone}
          fontSize="small"
          style={dynamic_icon_theme}
        />
      );
    } else {
      return (
        <AutorenewIcon
          className={classes.statusIcone}
          fontSize="small"
          style={dynamic_icon_theme}
        />
      );
    }
  };

  return (
    <Box alignSelf="center" p={1}>
      <Badge
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        badgeContent={
          <IconStatus
            failed={props.failed}
            in_progress={props.in_progress}
            success={props.success}
          />
        }
        overlap="circular">
        <IconCommitment
          classes={classes}
          failed={props.failed}
          in_progress={props.in_progress}
          success={props.success}
          theme={props.theme}
        />
      </Badge>
    </Box>
  );
};

DynamicIconTheme.propTypes = {
  className: PropTypes.string
};

export default DynamicIconTheme;
