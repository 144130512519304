import React from 'react';
import PropTypes from 'prop-types';
import StripeCheckout from 'react-stripe-checkout';

import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import { POST_SERVER } from 'common/post';
import { path } from 'common/path';

import { HomeHeader, DividerInBox, SubTitleBar } from 'components';
import { BigArea, SmallArea } from 'components';
import {
  TextFieldShow_json_1,
  Payment__1,
  Payment__2,
  Payment__3,
  Payment__8
} from 'assets/jss/all_styles';

const TextFieldShow = props => {
  return <Box style={TextFieldShow_json_1}>{props.contenu}</Box>;
};

const useStyles = makeStyles(Payment__1);

const Payment = props => {
  const classes = useStyles();

  const handleToken = amount => token => {
    const values = {
      token,
      amount: amount
    };
    const path_stripe_server = path + 'back/stripe_server';
    const var_from_POST_SERVER = POST_SERVER(path_stripe_server, values);
  };

  const stripeCeckoutVariable = (amount, description, name, button_text) => (
    <StripeCheckout
      stripeKey="pk_test_1xfdKTFg7ZeYwaHJr1XoD5nm0060GcH1bD"
      amount={amount}
      token={handleToken(amount, description, name)}
      label={'Pay : ' + amount + ' €'}
      panelLabel="Pay :"
      name="Payment"
      description=""
      ComponentClass="div"
      zipCode={false}
      currency="EUR"
      country="fr">
      <Button className="btn" variant="contained" style={Payment__2}>
        {button_text}
      </Button>
    </StripeCheckout>
  );

  return (
    <div style={Payment__3}>
      <HomeHeader
        title="Payements "
        quote_like_text="“Commitment leads to action. Action brings your dream closer.”"
      />
      <SubTitleBar subtitle="Payment interface " />
      <Paper style={Payment__8}>
        <div className={classes.root}>
          <Box p={2}>
            <BigArea alias={'Points'} classes={classes}>
              <SmallArea alias={'Get 10 points'} classes={classes}>
                <TextFieldShow
                  contenu={stripeCeckoutVariable(
                    10000,
                    'description',
                    'name',
                    'Add 10 points credits'
                  )}
                />
              </SmallArea>
              <SmallArea alias={'Get 20 points'} classes={classes}>
                <TextFieldShow
                  contenu={stripeCeckoutVariable(
                    2 * 10000,
                    'description',
                    'name',
                    'Add 20 points credits'
                  )}
                />
              </SmallArea>
              <SmallArea alias={'Get 50 points'} classes={classes}>
                <TextFieldShow
                  contenu={stripeCeckoutVariable(
                    5 * 10000,
                    'description',
                    'name',
                    'Add 50 points credits'
                  )}
                />
              </SmallArea>
            </BigArea>

            <DividerInBox />

            <BigArea alias={'Donate '} classes={classes}>
              <SmallArea alias={'Give 5 €'} classes={classes}>
                <TextFieldShow
                  contenu={stripeCeckoutVariable(
                    5 * 1000,
                    'description',
                    'name',
                    'Donate 5 € :=) '
                  )}
                />
              </SmallArea>
            </BigArea>
          </Box>
        </div>
      </Paper>
    </div>
  );
};
// }
Payment.propTypes = {
  className: PropTypes.string,
  // classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default Payment;
