import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';

import { makeStyles } from '@material-ui/styles';

import { POST_SERVER } from 'common/post';
import { path } from 'common/path';

import {
  FieldForInput,
  HaveAccount,
  ButtonSign,
  Sign,
  SubTitle,
  TitleLogo,
  FacebookButton,
  TransitionAlerts
} from 'components';
import schema_sign from 'common/schema_sign';
import { sign_up_style } from 'assets/jss/all_styles';
 

const useStyles = makeStyles(sign_up_style);

const SignInModule = props => {
  const { history } = props;
  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      email: '',
      password: '',
      last_name: '',
      first_name: '',
      id: '',
      avatar_url: ''
    },
    touched: {
      email: false,
      password: false
    },
    errors: {
      email: null,
      password: null
    },
    show_the_alert: false,
    message_to_show:'Error occured, it might be that the email is already in the database'
  });

  useEffect(() => {
    const errors = validate(formState.values, schema_sign);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const handleSign = async event => {
    event.preventDefault();
    if (history.location.pathname==='/front/sign_in') {
      try {
        let path_sign_in = path + 'back/log_in';
        const var_from_POST_SERVER = await POST_SERVER(
          path_sign_in,
          formState.values
        );
          console.log(var_from_POST_SERVER)
        const is_authenticated = var_from_POST_SERVER['is_authenticated'];

        let pathname = is_authenticated ? '/front/home' : '/front/sign_in';
        
        if (!is_authenticated){
          setFormState(formState => ({
            ...formState,
            show_the_alert: true,
            message_to_show: "Authentification has failed"
          }));

        history.push('/front/sign_up');
        }

        
         
        history.push({
          pathname: pathname
        });
      } catch (error) {
        console.log(error);
      }
    } else if (history.location.pathname==='/front/sign_up') {
      try {
        let path_sign_up = path + 'back/sign_up';
        const var_from_POST_SERVER = await POST_SERVER(
          path_sign_up,
          formState.values
        );

        const is_sign_up = var_from_POST_SERVER['is_sign_up'];

        console.log("is_sign_up : ")
        console.log(is_sign_up)

        if (is_sign_up) {
          history.push('/front/home');
        } else {

          setFormState(formState => ({
            ...formState,
            show_the_alert: true,
            message_to_show: "Sign up opperation failed"
          }));

          history.push('/front/sign_up');
        }
      } catch (error) {
        console.log(error);
      }
    }
  };


  const sendSocialInState = async () => {
    let path_providers_log = path + 'back/providers_log';
    const var_from_POST_SERVER = await POST_SERVER(
      path_providers_log,
      formState.values
    );
    const providers_log_is_done = var_from_POST_SERVER['providers_log_is_done'];

    if (providers_log_is_done) {
      history.push('/front/home');
    } else {
      history.push(history.location.pathname);
    } 
  }; 
  
  useEffect(() => {
    
    if (
      formState.values.last_name !== '' ||
      formState.values.first_name !== '' ||
      formState.values.id !== '' ||
      formState.values.avatar_url !== ''
    ) {
      sendSocialInState();
    }
  }, [formState.values]);



  const responseFacebook = async response => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        email: response.email,
        provider_id: response.id,
        first_name: response.first_name,
        last_name: response.last_name,
        avatar_url: response.picture.data.url
      }
    }));
  };

  return (
    <div className={classes.content}>
      <div className={classes.contentBody}>
        <form className={classes.form} onSubmit={handleSign}>
          <TitleLogo />
          <SubTitle subtitle="Accomplish the things that matters for you." />
          <TransitionAlerts
            show_the_alert={formState.show_the_alert}
            message_to_show={formState.message_to_show}
          /> 
          <FacebookButton responseFacebook={responseFacebook} />
          <FieldForInput
            name="email"
            formState={formState}
            label="Email address"
            handleChange={handleChange}
            type="text"
            hasError={hasError}
          />
          <FieldForInput
            name="password"
            formState={formState}
            label="Password"
            handleChange={handleChange}
            type="password"
            hasError={hasError}
          />
          <ButtonSign formState={formState} text="Continue" />
          <HaveAccount path={history.location.pathname} />
        </form>
      </div>
    </div>
  );
};

const SignInUp = props => {
  const { history } = props;

  return <Sign module={<SignInModule history={history} />} />;
};

SignInUp.propTypes = {
  history: PropTypes.object
};

export default withRouter(SignInUp);