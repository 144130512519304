import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { useState, useEffect } from 'react';
import { utcToZonedTime } from 'date-fns-tz'

import { makeStyles } from '@material-ui/styles';

import { GET_SERVER } from 'common/get';
import { POST_SERVER } from 'common/post';
import { path } from 'common/path';
import add_time_zone from 'common/add_time_zone';

import {
CommitmentLines,
TipsBar,
CommitmentToolsBar,
WelcomePanel
} from './components';
import { useStyles_json, home_1 } from 'assets/jss/all_styles';
import { HomeHeader } from 'components';

const Home = props => {
// set variables
const  useStyles  = makeStyles(useStyles_json);
const classes = useStyles();
const { history } = props;
const home_path = path + 'back/main_page';

// set alternative state variables
const [loading, setLoading] = React.useState(true);
const [interval_variable, setIntervalVariable] = React.useState(0);

// set state
const [formState, setFormState] = useState({
  string_filter_status: 'all',
  commitments_to_show: {},
  commitments_of_user: {},
  boolean_filter_status: {
    in_progress: true,
    success: true,
    failed: true
  }
});

// set async functions
const GetCommitmentsFromBackEnd = async () => {
  // console.log('formState : ' + formState);
  // console.log(formState);

  // const server_answer = await fetch(home_path, {
  //   method: 'POST',
  //   credentials: 'include',
  //   // hedears: 'Access-Control-Allow-Origin',
  //   body: JSON.stringify({
  //     input_values: formState.boolean_filter_status
  //   })
  // })
  //   .then(r => r.json())
  //   .then(j => {
  //     console.log('server answer :::  ' + JSON.stringify(j));
  //     return j;
  //   })
  //   .catch(err => console.log(err));

  const server_answer = await POST_SERVER(home_path, formState.boolean_filter_status)

  let commitments_of_user = {}

  Object.keys(server_answer['commitments_of_user']).forEach(function(key) {
    commitments_of_user[key] = server_answer['commitments_of_user'][key]

    function timeOffsettAdjust(time_var) {
      console.log("time_var before : ");
      console.log(time_var);
      var time_after_date_var = new Date(time_var);
      time_after_date_var.setTime(time_after_date_var.getTime() - (time_after_date_var.getTimezoneOffset() * 60 * 1000));
      console.log("time_after_date_var : ");
      console.log(time_after_date_var);
      return time_after_date_var;
    }

    commitments_of_user[key]['end_time'] = timeOffsettAdjust(commitments_of_user[key]['end_time']) 
    commitments_of_user[key]['start_time'] = timeOffsettAdjust(commitments_of_user[key]['start_time']) 

  });
  console.log("=============================================")
  console.log(commitments_of_user)
  console.log("=============================================")

  setFormState(formState => ({
    ...formState,
    commitments_of_user: commitments_of_user
  }));
  setLoading(false);
};

const handleCallCheckEndTime = async () => {
  let check_end_time_path = path + 'back/check_end_time';
  const var_from_GET_SERVER = await GET_SERVER(check_end_time_path);
  const check_end_time_of_commitments =
    var_from_GET_SERVER['check_end_time_of_commitments'];

  return check_end_time_of_commitments;
};

const handleCheckEndTime = async () => {
  console.log('handleCheckEndTime tick');
  handleCallCheckEndTime();
  GetCommitmentsFromBackEnd();
};

// set use effects
useEffect(
  () => {
    console.log('before setInterval');
    const interval = setInterval(handleCheckEndTime, 20000);
    setIntervalVariable(interval);
    console.log('after setInterval');
    return () => clearInterval(interval);
  }, 
  []
);


useEffect(() => {
  GetCommitmentsFromBackEnd();
}, [formState.boolean_filter_status]);
useEffect(() => {
  handleBooleanStatus();
}, [formState.string_filter_status]);

// set handle field functions
const handleBooleanStatus = () => {
  if (formState.string_filter_status==='in_progress') {
    var dictionary_new_filter = {
      'in_progress':true,
      'success': false,
      'failed': false
    } 
    setFormState(formState => ({
      ...formState,
      boolean_filter_status: dictionary_new_filter
      // {
        // ...formState.boolean_filter_status,
      //   ['in_progress']: true,
      //   ['success']: false,
      //   ['failed']: false
      // }
    }));
  } else if (formState.string_filter_status==='success') {
    var dictionary_new_filter = {
      'in_progress':false,
      'success': true,
      'failed': false
    }
    setFormState(formState => ({
      ...formState,
      boolean_filter_status: dictionary_new_filter
      // {
        // ...formState.boolean_filter_status,
      //   ['in_progress']: false,
      //   ['success']: true,
      //   ['failed']: false
      // }
    }));
  } else if (formState.string_filter_status==='failed') {
    var dictionary_new_filter = {
      'in_progress':false,
      'success': false,
      'failed': true
    }
    setFormState(formState => ({
      ...formState,
      boolean_filter_status: dictionary_new_filter

      // boolean_filter_status: {
        // ...formState.boolean_filter_status,
        // ['in_progress']: false,
        // ['success']: false,
        // ['failed']: true
      // }
    })); 
  } else if (formState.string_filter_status==='all') {
    var dictionary_new_filter = {
      'in_progress':true,
      'success': true,
      'failed': true
    }
    setFormState(formState => ({
      ...formState,
      boolean_filter_status: dictionary_new_filter

      // boolean_filter_status: {
        // ...formState.boolean_filter_status,
        // ['in_progress']: true,
        // ['success']: true,
        // ['failed']: true
      // }
    }));
  }
};

const handleFieldChange = (field, value) => {
  setFormState(formState => ({
    ...formState,
    [field]: value
  }));
};

return (
  <div style={home_1}>
    <HomeHeader
      title="Commitment made "
      quote_like_text="“Commitment leads to action. Action brings your dream closer.”"
    />
    <TipsBar />
    <CommitmentToolsBar
      classes={classes}
      formState={formState}
      history={history}
      interval_variable={interval_variable}
      handleFieldChange={handleFieldChange}
    />
    <CommitmentLines
      classes={classes}
      history={history}
      loading={loading}
      commitments_of_user={formState.commitments_of_user}
      interval_variable={interval_variable}
    />
    <WelcomePanel />
  </div>
);
};

Home.propTypes = {
className: PropTypes.string,
// classes: PropTypes.object.isRequired,
history: PropTypes.object.isRequired
};
export default compose(
withRouter
// withStyles(styles)
)(Home);
