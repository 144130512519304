/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";

import styles from "assets/jss/material-kit-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        {/* <div className={classes.left}> */}
          {/*<List className={classes.list}>*/}
          {/*  <ListItem className={classes.inlineBlock}>*/}
          {/*    <a*/}
          {/*      href="https://www.creative-tim.com/?ref=mkr-footer"*/}
          {/*      className={classes.block}*/}
          {/*      target="_blank"*/}
          {/*    >*/}
          {/*      Creative Tim*/}
          {/*    </a>*/}
          {/*  </ListItem>*/}
          {/*  <ListItem className={classes.inlineBlock}>*/}
          {/*    <a*/}
          {/*      href="https://www.creative-tim.com/presentation?ref=mkr-footer"*/}
          {/*      className={classes.block}*/}
          {/*      target="_blank"*/}
          {/*    >*/}
          {/*      About us*/}
          {/*    </a>*/}
          {/*  </ListItem>*/}
          {/*  <ListItem className={classes.inlineBlock}>*/}
          {/*    <a*/}
          {/*      href="http://blog.creative-tim.com/?ref=mkr-footer"*/}
          {/*      className={classes.block}*/}
          {/*      target="_blank"*/}
          {/*    >*/}
          {/*      Blog*/}
          {/*    </a>*/}
          {/*  </ListItem>*/}
          {/*  <ListItem className={classes.inlineBlock}>*/}
          {/*    <a*/}
          {/*      href="https://www.creative-tim.com/license?ref=mkr-footer"*/}
          {/*      className={classes.block}*/}
          {/*      target="_blank"*/}
          {/*    >*/}
          {/*      Licenses*/}
          {/*    </a>*/}
          {/*  </ListItem>*/}
          {/*</List>*/}
        {/* </div> */}
        <div className={classes.left}>
          {/* &copy;  */}
          {1900 + new Date().getYear()} , methode  - 
          <a
          href="https://www.termsandconditionsgenerator.com/live.php?token=ehgsfFlyuKxd0Ka9xIcSsMbckunpJwsF"
          className={aClasses}
          target="_blank"
          >
          Terms and conditions
          </a>
          <span />
          {/* <br/> */}
          <a
          href="https://www.privacypolicygenerator.info/live.php?token=Bn8ilQwnIER7Pk8Xo5YjSmRoZhh8h6i0"
          className={aClasses}
          target="_blank"
          >
          Privacy policy 
          </a>


          {/*{" "}*/}
          {/*<Favorite className={classes.icon} /> */}
          {/*by{" "}*/}
          {/*<a*/}
          {/*  href="https://www.creative-tim.com?ref=mkr-footer"*/}
          {/*  className={aClasses}*/}
          {/*  target="_blank"*/}
          {/*>*/}
          {/*  Creative Tim*/}
          {/*</a>{" "}*/}
          {/*for a better web.*/}
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
};
