import React from 'react';
import Box from '@material-ui/core/Box';

import { IconeAndTextField_json } from 'assets/jss/all_styles';

const IconeAndTextField = props => {
  return (
    <Box display="flex" style={IconeAndTextField_json}>
      <Box p={1}>{props.icone}</Box>
      <Box p={1} alignSelf="center">
        {props.text}
      </Box>
    </Box>
  );
};

export default IconeAndTextField;
