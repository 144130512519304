import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import Box from '@material-ui/core/Box';

import OutlinedInput from '@material-ui/core/OutlinedInput';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import {
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  // IconButton,
  TextField,
  Typography
} from '@material-ui/core';
import { SmallArea } from 'components';

import { 
  choice_sunflowers_icon,
  choice_sunflowers_input 
} from 'assets/jss/all_styles';


const ChoiceSunflowers = props => {
  return (
    <SmallArea alias={'Use your Sunflowers *'} classes={props.classes}>
      <Box p={0} display="flex">
        <Checkbox
          // defaultChecked

          iconstyle={choice_sunflowers_icon}
          inputstyle={choice_sunflowers_input}
          style={choice_sunflowers_input}
          onChange={event =>
            props.handleFieldChange('use_sunflowers', event.target.checked)
          }

          // name="use_social_share"
          // inputProps={{ 'aria-label': 'secondary checkbox' }}
        />
        <Box p={2}>{'I chose to use my Sunflowers'}</Box>
      </Box>
      {/* onChange={handleChange} */}
    </SmallArea>
  );
};

ChoiceSunflowers.propTypes = {
  className: PropTypes.string
};

export default ChoiceSunflowers;
