import React from "react";
import classNames from "classnames";
import PropTypes from 'prop-types';

import { makeStyles } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";

import push_your_self from 'assets/img/use_cases_web/climb.png';
import organize from 'assets/img/use_cases_web/laptop.png';
import note_and_save from 'assets/img/use_cases_web/note.png';


const useStyles = makeStyles(styles);

const AreaContainerGrid = props => {
  return  (
    <GridItem xs={12} sm={12} md={4}>
      <Card plain>
        <GridItem xs={12} sm={12} md={6} className={props.itemGrid}>
          <Box >
            <img src={props.imagepath} alt="..."  className={props.imageClasses} />
          </Box>
        </GridItem>
        <h4 className={props.cardTitle}>
          {props.title}
          <br />
        </h4>
        <CardBody>
          <p className={props.description}>
            {props.text}
          </p>
        </CardBody>
      </Card>
    </GridItem>
  )
}
const TeamSection = props => {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      <h1 className={classes.title}>Method helps you to </h1>
      <div>
        <GridContainer>
          <AreaContainerGrid
            itemGrid = {classes.itemGrid}
            imagepath = {push_your_self}
            imageClasses = {imageClasses}
            cardTitle = {classes.cardTitle}
            description = {classes.description}
            title = {'Push your self where and when you want'}
            text = {'Chose what you want to commit your self to'}
          />
          <AreaContainerGrid
            itemGrid = {classes.itemGrid}
            imagepath = {note_and_save}
            imageClasses = {imageClasses}
            cardTitle = {classes.cardTitle}
            description = {classes.description}
            title = {'Note and save your ideas'}
            text = {'Note everything you want to save and consult them when you want'}
          />
          <AreaContainerGrid
            itemGrid = {classes.itemGrid}
            imagepath = {organize}
            imageClasses = {imageClasses}
            cardTitle = {classes.cardTitle}
            description = {classes.description}
            title = {'Orginaze your thoughts and objectives'}
            text = {'Write what you want to realize in an intuitive pad'}
          />
        </GridContainer>
      </div>
    </div>
  );
}
TeamSection.propTypes = {
  className: PropTypes.string
};

export default TeamSection;

