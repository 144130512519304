import React from 'react';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import { 
  HomeHeader_json_1,
  HomeHeader_json_2,
  HomeHeader_json_3
} from 'assets/jss/all_styles';

const HomeHeader = props => {
  return (
    <Box
      alignSelf="center"
      display="flex"
      flexGrow={1}
      p={0}
      style={HomeHeader_json_1}>
      <Box display="flex" flexGrow={1} p={0}>
        {props.title}
      </Box>
      <Box
        alignSelf="center"
        flexGrow={0}
        justifyContent="flex-end"
        p={0}
        style={HomeHeader_json_2}>
        <Typography style={HomeHeader_json_3}>
          {props.quote_like_text}
        </Typography>
        <Box
          alignSelf="center"
          flexGrow={0}
          justifyContent="flex-end"
          p={0}
          style={HomeHeader_json_2}>
          <Typography align="right" style={HomeHeader_json_3}>
            {' M. W.'}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default HomeHeader;
