import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { admin_style_json } from 'assets/jss/all_styles';

import { GET_SERVER } from 'common/get';
import { POST_SERVER } from 'common/post';
import { path } from 'common/path';

import { FilterByStatus, LineProofAdmin } from './components';
import VideocamIcon from '@material-ui/icons/Videocam';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';

import { HomeHeader, DividerInBox, SubTitleBar } from 'components';
import Paper from '@material-ui/core/Paper';

import { 
  home_1
} from 'assets/jss/all_styles';

const proof_path = path + 'back/admin';
const useStyles = makeStyles(admin_style_json);

const Admin = props => {
  const { history } = props
  const classes = useStyles();

  const [formState, setFormState] = React.useState({
    formatted_proofs: {},
    state_of_proof_as_string: 'all',
    decline: true,
    accept: true,
    in_progress: true,
    isValid: false,
    isLoading: false,
    submitError: null
  });

  //  const [loading, setLoading] = React.useState(true);
  //   const [interval_variable, setIntervalVariable] = React.useState(false);

  useEffect(() => {
    GetProofsFromBackEnd();
  }, []);

  //   useEffect(() => {
  //     GetProofsFromBackEnd();
  //   }, [1000]);

  const handleChange = async value => {
    // console.log('start handleChange');
    // console.log('value : ');
    // console.log(value);

    setFormState(formState => ({
      ...formState,
      state_of_proof_as_string: value
    }));

    if (value==='accept') {
      formState.decline = false;
      formState.accept = true;
      formState.in_progress = false;
    }

    if (value==='decline') {
      formState.decline = true;
      formState.accept = false;
      formState.in_progress = false;
    }

    if (value==='in_progress') {
      formState.decline = false;
      formState.accept = false;
      formState.in_progress = true;
    }

    if (value==='all') {
      formState.decline = true;
      formState.accept = true;
      formState.in_progress = true;
    }

    // setFormState(formState => ({
    // 	...formState,
    // 	state_of_proof_as_string: value,
    // }));

    // update formState ??

    // await setFormState(formState => ({
    // 	...formState,
    // 	values: values,
    // }));
    await GetProofsFromBackEnd();
  };

  const GetProofsFromBackEnd = async () => {
    // console.log('formState : ' + formState);
    // console.log(formState);

    const json_to_send = {
      input_values: {
        decline: formState.decline,
        accept: formState.accept,
        in_progress: formState.in_progress
      }
    };

    const server_answer = await fetch(proof_path, {
      method: 'POST',
      credentials: 'include',
      hedears: 'Access-Control-Allow-Origin',
      body: JSON.stringify(json_to_send)
    })
      .then(r => r.json())
      .then(j => {
        console.log('server answer :::  ' + JSON.stringify(j));
        return j;
      })
      .catch(err => console.log(err));

    // console.log('server_answer : ' + server_answer);
    // console.log(server_answer);
    // console.log('formatted_proofs 1: ');
    // console.log(server_answer['formatted_proofs']);

    setFormState(formState => ({
      ...formState,
      formatted_proofs: server_answer['formatted_proofs']
    }));

    // console.log('props : ');
    // console.log(props);
    // console.log('formatted_proofs 2: ');
    // console.log(server_answer['formatted_proofs']);
    // console.log('formState : ');
    // console.log(formState);
  };

  const handleDownloadFile = async proof_id_to_dowload => {
    // console.log('start handleDownloadFile');
    try {
      //   console.log('proof_id_to_dowload : ' + proof_id_to_dowload);
      //   console.log(proof_id_to_dowload);

      const json_to_send = {
        input_values: {
          id: proof_id_to_dowload
        }
      };

      function saveBlob(blob, filename) {
        console.log('filename : ');
        console.log(filename);
        const downloadUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        console.log("downloadUrl");
        console.log(downloadUrl);
        link.download = filename;
        link.click();
        link.remove();
      }

      let path_download = path + 'back/proof_by_id';
      // POST_SERVER(path_download,{
      // 	id: proof_id_to_dowload
      // 	})
      fetch(path_download, {
        method: 'POST',
        credentials: 'include',

        // method: 'POST',
        body: JSON.stringify(json_to_send)
        // headers: {
        // 	'Accept': 'application/json',
        // 	'Content-Type': 'application/json'
        // }
      }).then(response => {
        console.log('response');
        console.log(response);
        response.headers.forEach(console.log);
        response.blob().then(blob => {
          console.log("response")
          console.log(response)
          // console.log(response.headers.get('content-disposition'))
          saveBlob(
            blob,
            response.headers.get('content-disposition').split('=')[1]
          );
        });
      });
    } catch (error) {
      setFormState(formState => ({
        ...formState,
        isLoading: false,
        serviceError: error
      }));
    }
  };

  const handleDeclineProof = async proof_id_to_decline => {
    try {
      const json_to_send = {
        id: proof_id_to_decline
      };

      setFormState(formState => ({
        ...formState,
        isLoading: true
      }));

      let path_decline_proof = path + 'back/decline_proof';
      const var_from_POST_SERVER = await POST_SERVER(
        path_decline_proof,
        json_to_send
      );
      const proof_was_decline = var_from_POST_SERVER['proof_was_decline'];

      await GetProofsFromBackEnd();
    } catch (error) {
      setFormState(formState => ({
        ...formState,
        isLoading: false,
        serviceError: error
      }));
    }
  };

  const handleAcceptProof = async proof_id_to_accept => {
    try {
      const json_to_send = {
        id: proof_id_to_accept
      };

      setFormState(formState => ({
        ...formState,
        isLoading: true
      }));

      let path_accept_proof = path + 'back/accept_proof';
      const var_from_POST_SERVER = await POST_SERVER(
        path_accept_proof,
        json_to_send
      );
      const proof_was_accepted = var_from_POST_SERVER['proof_was_accepted'];

      await GetProofsFromBackEnd();
    } catch (error) {
      setFormState(formState => ({
        ...formState,
        isLoading: false,
        serviceError: error
      }));
    }
  };

  const iconPreuve = preuve => {
    if (preuve['proof_type']==='video') {
      return <VideoLibraryIcon />;
    }
    if (preuve['proof_type']==='photo') {
      return <VideocamIcon />;
    }
  };

  const colorPreuve = (preuve, classes) => {
    if (preuve['decline']===true) {
      return classes.red;
    }
    if (preuve['accept']===true) {
      return classes.green;
    }
    if (preuve['in_progress']===true) {
      return classes.blue;
    }
  };

  return (
    <div className={classes.root} style={home_1}>
      <HomeHeader
        title="Administration "
        quote_like_text="“Commitment leads to action. Action brings your dream closer.”"
      />
      <SubTitleBar subtitle="Admin interface " />
      <FilterByStatus formState={formState} handleChange={handleChange} />
      <Paper>
        {Object.keys(formState.formatted_proofs).map(order_of_proof => (
          <LineProofAdmin
            order_of_proof={order_of_proof}
            formState={formState}
            setFormState={setFormState}
            handleDownloadFile={handleDownloadFile}
            colorPreuve={colorPreuve}
            iconPreuve={iconPreuve}
            handleAcceptProof={handleAcceptProof}
            handleDeclineProof={handleDeclineProof}
            history={history}
          />
        ))}
      </Paper>
    </div>
  );
};

export default Admin;