import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Grid
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';

import { sign_up_style } from 'assets/jss/all_styles';

const useStyles = makeStyles(sign_up_style);


const Sign = props => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid item xs={12} >
        <Paper className={classes.paper}>{props.module}</Paper>
      </Grid>
    </div>
  );
};

Sign.propTypes = {
  className: PropTypes.string
};

export default Sign;
