import React from 'react';
import PropTypes from 'prop-types';

import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import PhoneRoundedIcon from '@material-ui/icons/PhoneRounded';
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';
import SportsBaseballIcon from '@material-ui/icons/SportsBaseball';
import { useStyles_json } from 'assets/jss/all_styles';
import { makeStyles } from '@material-ui/styles';

const IconCommitment = props => {
  const classes = makeStyles(useStyles_json);

  switch (props.theme) {
    case 'Other':
      return <HelpOutlineIcon
        className={classes.themeIcone}
        fontSize="large"
      /> //classes={props.classes} <HelpOutlineIcon fontSize="large" />
    case 'Education':
      return <MenuBookIcon
        className={classes.themeIcone}
        fontSize="large"
      />
    case 'Sport':
      return <SportsBaseballIcon
        className={classes.themeIcone}
        fontSize="large"
      />
    case 'Work':
      return <WorkOutlineIcon
        className={classes.themeIcone}
        fontSize="large"
      />
    case 'Life':
      return <EmojiObjectsIcon
        className={classes.themeIcone}
        fontSize="large"
      />
    case 'Contact':
      return <PhoneRoundedIcon
        className={classes.themeIcone}
        fontSize="large"
      />
    case 'Game':
      return <SportsEsportsIcon
        className={classes.themeIcone}
        fontSize="large"
      />
    default:
      return <HelpOutlineIcon
        className={classes.themeIcone}
        fontSize="large"
      />
  }
}

IconCommitment.propTypes = {
  className: PropTypes.string
};

export default IconCommitment;
