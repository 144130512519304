import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  TextField
} from '@material-ui/core';

import { sign_up_style } from 'assets/jss/all_styles';

const useStyles = makeStyles(sign_up_style);

const FieldForInput = props => {
  const classes = useStyles();
  return (
    <TextField
      align="center"
      className={classes.textField}
      error={props.hasError(props.name)}
      fullWidth
      helperText={
        props.hasError(props.name)
          ? props.formState.errors[props.name][0]
          : null
      }
      label={props.label}
      margin="dense"
      name={props.name}
      onChange={props.handleChange}
      type={props.type}
      // value double bar ( || ) for a bug probably
      value={props.formState.values[props.name] || ''}
      variant="outlined"
    />
  );
};

FieldForInput.propTypes = {
  className: PropTypes.string
};

export default FieldForInput;
