import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import PostAddIcon from '@material-ui/icons/PostAdd';
import Link from '@material-ui/core/Link';
import { withStyles } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import {
  CommitmentToolsBar_json_1,
  CommitmentToolsBar_json_2
} from 'assets/jss/all_styles';

const BootstrapInput = withStyles(CommitmentToolsBar_json_1)(InputBase);

const CommitmentToolsBar = props => {

  return (
    <Box
      alignSelf="center"
      display="flex"
      flexGrow={1}
      flexWrap="wrap"
      p={0}
      >
      <Box
        alignSelf="center"
        flexGrow={1}
        p={0}
        >
        {'My list of commitments '}
      </Box>

      <Link
        component="button"
        variant="body2"
        className={props.classes.newCommitment}
        onClick={() => {
          // clearInterval(props.interval_variable);
          props.history.push({ pathname: '/front/append_commitment' });
        }}>
        <Box alignSelf="center" display="flex" flexGrow={0} p={0}>
          <PostAddIcon />
          <Box alignSelf="center" display="flex" flexGrow={0} p={0}>
            New commitment
          </Box>
        </Box>
      </Link>
      <Box
        alignSelf="center"
        display="flex"
        flexGrow={0}
        flexWrap="wrap"
        justifyContent="flex-end"
        p={0}>
        <Select
          id="demo-customized-select"
          input={<BootstrapInput />}
          labelId="demo-customized-select-label"
          onChange={event =>
            props.handleFieldChange('string_filter_status', event.target.value)
          }
          value={props.formState.string_filter_status}>
          <MenuItem
            SelectProps={{
              MenuProps: {
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left'
                },
                getContentAnchorEl: null
              }
            }}
            value={'all'}>
            {' '}
            all commitments{' '}
          </MenuItem>
          <MenuItem
            SelectProps={{
              MenuProps: {
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left'
                },
                getContentAnchorEl: null
              }
            }}
            value={'in_progress'}>
            commitments in progress
          </MenuItem>
          <MenuItem
            SelectProps={{
              MenuProps: {
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left'
                },
                getContentAnchorEl: null
              }
            }}
            value={'success'}>
            successful commitments
          </MenuItem>
          <MenuItem
            SelectProps={{
              MenuProps: {
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left'
                },
                getContentAnchorEl: null
              }
            }}
            value={'failed'}>
            failed commitments
          </MenuItem>
        </Select>
      </Box>
    </Box>
  );
};

CommitmentToolsBar.propTypes = {
  className: PropTypes.string
};

export default CommitmentToolsBar;