import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider} from '@material-ui/core';
import _ from 'underscore';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';
import { SmallArea } from 'components';
import { myTheme_json } from 'assets/jss/all_styles';
import { date_term } from 'assets/jss/all_styles';
import { makeStyles } from '@material-ui/styles';
import { createTheme } from '@material-ui/core/styles';

import MomentUtils from '@date-io/moment';

const DateTerm = props => {
  const myTheme = createTheme(myTheme_json)
  return (
    <SmallArea alias={'Date of term  *'} classes={props.classes}>
      <ThemeProvider theme={myTheme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} myTheme>
          <KeyboardDateTimePicker
            value={props.values.end_date}
            disablePast
            inputVariant="outlined"
            ampm={false}
            onChange={date => {
              console.log("date :");
              console.log(date);
              console.log("getTimezoneOffset :");
              console.log(date.getTimezoneOffset());
              props.handleFieldChange('end_date', date)
              }
            }
            // label="Keyboard with error handler"
            onError={console.log}
            // minDate={new Date("2018-01-01T00:00")}
            format="yyyy/MM/dd HH:mm"
            InputProps={{
              classes: { root: props.classes.KeyboardDatePickerTheme }
            }}
            classes={{ root: props.classes.KeyboardDatePickerTheme }}
            style={date_term}
        />

          {/* <KeyboardDateTimePicker
            // clearable
            // openTo="hours"
            //  views={["hours", "minutes", "seconds"]}
            inputVariant="outlined"
            fullWidth
            margin="dense"
            value={props.values.end_date}
            // value={this.add_weeks(this.state.values.end_date, 1).toString()}
            onChange={date => {
              console.log("date :");
              console.log(date);
              console.log(date.getTimezoneOffset());
              props.handleFieldChange('end_date', date)
              }
            }
            // onChange={this.hanleDateChange}
            // label="end time"
            //onError={console.log}
            // minDate={new Date("2019-01-01T00:00")}
            disablePast
            // format="yyyy/MM/dd hh:mm a"
            format="yyyy/MM/dd HH:mm"
            variant="inline"
            ampm={false}
            // InputProps={{ className: classes.KeyboardDatePickerTheme }}
            InputProps={{
              classes: { root: props.classes.KeyboardDatePickerTheme }
            }}
            classes={{ root: props.classes.KeyboardDatePickerTheme }}
            style={date_term}
          /> */}
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </SmallArea>
  );
};

DateTerm.propTypes = {
  className: PropTypes.string
};

export default DateTerm;