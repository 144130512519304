import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core';

import {
  BigArea,
  SmallArea,
  TextFieldShow,
  IconeAndTextField
} from 'components';

import TermFormat from 'common/TermFormat';
import is_empty from 'common/is_empty';
import { POST_SERVER } from 'common/post';
import { path } from 'common/path';

import sunflower from 'assets/img/icons8-sunflower-50.png';
import { show_commitment_style } from 'assets/jss/all_styles';
import { Payment__3, Payment__8 } from 'assets/jss/all_styles';
import { HomeHeader, DividerInBox, SubTitleBar } from 'components';

import {
  IconCommitment,
  IconProofType,
  IconStatus,
  ShowCommitmentFile,
  ShowProof
} from './components';

class ShowCommitement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      commitment: {},
      proof: {}
    };
  }

  fillCommitmentStateFromLocalStorage = async () => {
    this.setState({
      commitment: JSON.parse(localStorage.getItem('commitment'))
    });
  };
  componentDidMount = async () => {
    await this.fillCommitmentStateFromLocalStorage();
    await this.handleLoadProof();
  };
  handleStatus = () => {
    if (this.state.commitment.failed === 'true') {
      return 'failed';
    }
    if (this.state.commitment.in_progress === 'true') {
      return 'ongoing';
    }
    if (this.state.commitment.success === 'true') {
      return 'success';
    }
  };
  handleLoadProof = async () => {
    const commitment_has_proof = this.handleCommitmentHasProof();
    if (commitment_has_proof) {
      let proof_of_commitment = await this.handleProofOfCommitment();

      this.setState({
        proof: proof_of_commitment
      });
    }
  };
  handleCommitmentHasProof = async () => {
    const commitment = this.state.commitment;

    let commitment_has_proof_path = path + 'back/commitment_has_proof';
    const var_from_POST_SERVER = await POST_SERVER(
      commitment_has_proof_path,
      commitment
    );
    const commitment_has_proof = var_from_POST_SERVER['commitment_has_proof'];

    return commitment_has_proof;
  };
  handleProofOfCommitment = async () => {
    const commitment = this.state.commitment;

    let proof_of_commitment_path = path + 'back/proof_of_commitment';
    const var_from_POST_SERVER = await POST_SERVER(
      proof_of_commitment_path,
      commitment
    );
    const proof_of_commitment = var_from_POST_SERVER['proof_of_commitment'];

    return proof_of_commitment;
  };

  render() {
    const { classes, ...rest } = this.props;

    const { commitment, proof } = this.state;
    const status = this.handleStatus();

    return (
      <div style={Payment__3}>
        <HomeHeader
          title="Commitment "
          quote_like_text="“Commitment leads to action. Action brings your dream closer.”"
        />
        <SubTitleBar subtitle="Information about your commitment  " />

        <Paper style={Payment__8}>
          <Box p={2}>
            <BigArea alias={'Commitment'} classes={classes}>
              <SmallArea alias={'Name '} classes={classes}>
                <TextFieldShow contenu={commitment.title} />
              </SmallArea>
              <SmallArea alias={'Theme '} classes={classes}>
                <IconeAndTextField
                  icone={<IconCommitment theme={commitment.theme} />}
                  text={commitment.theme}
                />
              </SmallArea>
            </BigArea>

            <DividerInBox />

            <BigArea alias={'Proof'} classes={classes}>

              <SmallArea alias={'File of Proof'} classes={classes}>

                {!is_empty(proof)        
                ? 
                <ShowProof proof={proof} proof_type={commitment.proof_type} />
                : 
                <TextFieldShow contenu={'No proof is yet saved'} />  
                }

              </SmallArea>

              <SmallArea alias={'Type of the proof '} classes={classes}>
                <IconeAndTextField
                  icone={<IconProofType proof_type={commitment.proof_type} />}
                  text={commitment.proof_type}
                />
              </SmallArea>
              {/* <SmallArea alias={'Proof description file'} classes={classes}>
                <ShowCommitmentFile commitment={commitment} />
              </SmallArea> */}
            </BigArea>

            <DividerInBox />

            <BigArea
              alias={
                <Box display="flex" alignSelf="center">
                  <Box alignSelf="center">Motivation</Box>
                  <Box p={1}></Box>
                  <Box alignSelf="center">
                    <img alt="Sunflowers" width="34px" src={sunflower} />
                  </Box>
                </Box>
              }
              classes={classes}>
              <SmallArea alias={'Use Sunflowers'} classes={classes}>
                <IconeAndTextField
                  icone={
                    <Box alignSelf="center">
                      <img alt="Sunflowers" width="34px" src={sunflower} />
                    </Box>
                  }
                  text={
                    commitment.use_sunflowers === 'true'
                      ? 'Yes, consume my Sunflowers if I fail'
                      : 'No Sunflower is gonna be consumed'
                  }
                />
              </SmallArea>
              <SmallArea alias={'Number of Sunflowers '} classes={classes}>
                <TextFieldShow contenu={commitment.number_sunflowers} />
              </SmallArea>
              <SmallArea alias={'Shared in social media ?'} classes={classes}>
                <TextFieldShow
                  contenu={
                    commitment.use_social_share === 'true'
                      ? 'Yes, it was made public'
                      : 'Not shared'
                  }
                />
              </SmallArea>
            </BigArea>

            <DividerInBox />

            <BigArea alias={'Status'} classes={classes}>
              <SmallArea alias={'Start Time'} classes={classes}>
                <TextFieldShow contenu={commitment.start_time} />
              </SmallArea>
              <SmallArea alias={'Status'} classes={classes}>
                <IconeAndTextField
                  icone={<IconStatus classes={classes} status={status} />}
                  text={status}
                />
              </SmallArea>
            </BigArea>

            <DividerInBox />

            <BigArea alias={'Term'} classes={classes}>
              <SmallArea alias={'Date of term '} classes={classes}>
                <TextFieldShow contenu={commitment.end_time} />
              </SmallArea>
              <SmallArea alias={'Remaining time '} classes={classes}>
                <TextFieldShow contenu={TermFormat(commitment.end_time)}/>
              </SmallArea>
            </BigArea>
          </Box>
        </Paper>
      </div>
    );
  }
}
ShowCommitement.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  withStyles(show_commitment_style)
)(ShowCommitement);
