import React from 'react';
import { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import HomeIcon from '@material-ui/icons/Home';

import { GET_SERVER } from 'common/get';
import { path } from 'common/path';

import logo from 'assets/img/methode_dynamique_rogner_width_500px.png';
import { Sidebar_theme_json } from 'assets/jss/all_styles';

import { Profile } from './';
import { SidebarNav } from './';

const useStyles = makeStyles(Sidebar_theme_json);

const Sidebar = props => {
  const { open, handleDrawerClose, handleDrawerOpen } = props;

  const classes = useStyles();
  const theme = useTheme();
  
  const [formState, setFormState] = useState({
    values: {
      email: '',
      point: '',
      first_name: '',
      avatar_url: '',
      role:''
    },
    pages: [
      {
        title: 'Profil',
        href: '/front/profile',
        icon: <AccountCircleIcon />
      },
      {
        title: 'Home',
        href: '/front/home',
        icon: <HomeIcon />
      }
    ]

  });
  
  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    try {
      let path_profile_data = path + 'back/profile_data';
      const var_from_POST_SERVER = await GET_SERVER(path_profile_data);
      const profile_data = var_from_POST_SERVER['profile_data'];
      
      console.log("profile_data['roles']")
      console.log(profile_data['roles'])
      
      if(profile_data['roles'] === 'admin'){
        console.log('in admin')
        setFormState(formState => ({
          ...formState,
          pages: [
            {
              title: 'Profil',
              href: '/front/profile',
              icon: <AccountCircleIcon />
            },
            {
              title: 'Home',
              href: '/front/home',
              icon: <HomeIcon />
            },          {
              title: 'Admin',
              href: '/front/admin',
              icon: <SupervisorAccountIcon />
              }
          ]
        }));
      }

      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          email: profile_data['email'],
          point: profile_data['point'],
          first_name: profile_data['first_name'],
          avatar_url: profile_data['avatar_url'],
          role: profile_data['roles']
        }
      }));
    } catch (error) {
      console.log(error);
    }
  };

  // const pages = [
  //   {
  //     title: 'Profil',
  //     href: '/profil',
  //     icon: <AccountCircleIcon />
  //   },
  //   {
  //     title: 'Home',
  //     href: '/front/home',
  //     icon: <HomeIcon />
  //   }
  // ];

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })
      }}>
      <div className={classes.toolbar}>
        <div className={classes.logoWrapper}>
          {open && (
            <Link className={classes.logoLink} to="/">
              <img
                alt="Methode logo"
                width="120px"
                style={{ marginRight: '8px', marginLeft: '16px' }}
                className={classes.logoImage}
                src={logo}
              />
            </Link>
          )}
        </div>
        {open ? (
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        ) : (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            // edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open
            })}>
            <MenuIcon />
          </IconButton>
        )}
      </div>
      <Divider />

      <Profile
        classes={classes}
        open={open}
        email={formState.values.email}
        avatar={formState.values.avatar_url}
        point={formState.values.point}
      /> 

      <Divider />
      <SidebarNav className={classes.nav} pages={formState.pages} open={open} />
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  handleDrawerClose: PropTypes.func,
  open: PropTypes.bool.isRequired
};

export default Sidebar;
