import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
// @material-ui/icons
import Menu from '@material-ui/icons/Menu';
// core components
import styles from 'assets/jss/material-kit-react/components/headerStyle.js';

// import logo from 'assets/img/methode_titre_v3.png';
import logo from 'assets/img/methode_dynamique_rogner_width_500px.png';

import GridItem from 'components/Grid/GridItem.js';

import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Slide from '@material-ui/core/Slide';
import PersonIcon from '@material-ui/icons/Person';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import {Link} from 'react-router-dom';
import {withStyles} from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';


const useStyles = makeStyles(styles);


const linkStyle = {
  link: {
    color: '#000000',
    '&:hover': {
      color: '#f0b400',
    }
  }
}

// style={{ color: 'gray','&:hover': {
//   color: '#000000',
//   textDecoration: 'underline #000000'
// } }}

// eslint-disable-next-line no-unused-vars
// const linkStyle = withStyles((theme) => ({
//   day: {
//     color : 'red',

//     '&:hover': {
//       color: 'blue'
//     }
//   },
// }));
const useStylesLink = makeStyles(linkStyle);
const ButtonTest = props => {
  return ( 'TEXT'

  )
}

const ButtonSign = (props) => {
  return (<Box
    alignSelf="center"
    color="#000000"
    display="flex"
    flexGrow={0}
    p={1}
  >

    <Box
      alignSelf="center"
      display="flex"
      p={0}
    >
      <PersonIcon />
    </Box>
    <Box
      alignSelf="center"
      display="flex"
      p={1}
      style={{ color: 'red','hover': {
        color: '#000000',
        textDecoration: 'underline #000000'
      } }}
    >        Sign in
    </Box>
  </Box>
  )
}



const HideOnScroll = (props) => {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide
      appear={false}
      direction="down"
      in={!trigger}
    >
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const HideAppBar = (props) => {
  return (
    <React.Fragment>
      <CssBaseline />
      <HideOnScroll {...props}>
        <AppBar>
          <Toolbar style={{ 'backgroundColor':'#ffffff' }} >
            {props.children}
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      {/*<Toolbar />*/}
    </React.Fragment>
  );
}


export default function Header(props) {
  const classes = useStyles();
  const link_classes = useStylesLink();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const [mobileOpen, setMobileOpen] = React.useState(false);
  React.useEffect(() => {
    if (props.changeColorOnScroll) {
      window.addEventListener('scroll', headerColorChange);
    }
    return function cleanup() {
      if (props.changeColorOnScroll) {
        window.removeEventListener('scroll', headerColorChange);
      }
    };
  });
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const headerColorChange = () => {
    const { color, changeColorOnScroll } = props;
    const windowsScrollTop = window.pageYOffset;
    if (windowsScrollTop > changeColorOnScroll.height) {
      document.body
        .getElementsByTagName('header')[0]
        .classList.remove(classes[color]);
      document.body
        .getElementsByTagName('header')[0]
        .classList.add(classes[changeColorOnScroll.color]);
    } else {
      document.body
        .getElementsByTagName('header')[0]
        .classList.add(classes[color]);
      document.body
        .getElementsByTagName('header')[0]
        .classList.remove(classes[changeColorOnScroll.color]);
    }
  };
  const { color, rightLinks, leftLinks, brand, fixed, absolute } = props;
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed
  });
  const brandComponent = <Button className={classes.title}>{brand}</Button>;
  return (
    <HideAppBar className={appBarClasses}>
      <Box
        alignSelf="center"
        display="flex"
        flexGrow={1}
        p={1}
      >

        <Box
          alignSelf="center"
          flexGrow={1}
          // bgcolor="grey.300"
          p={0}
        >
          <img
            alt="..."
            className={imageClasses}
            src={logo}
            width="100px"
          />
        </Box>



          
        <Link
          className={link_classes.link}
          to="/front/sign_in"
        >
          <Box
            alignSelf="center"
            display="flex"
            flexGrow={0}
            p={1}
          >
            <Box
              alignSelf="center"
              display="flex"
              p={0}
            >
              <PersonIcon />
            </Box>
            <Box
              alignSelf="center"
              display="flex"
              p={1}
            >
              Sign in
            </Box>
          </Box>
        </Link>

        <Link
          className={link_classes.link}
          to="/front/sign_up"
        >
          <Box
            alignSelf="center"
            display="flex"
            flexGrow={0}
            p={1}
          >
            <Box
              alignSelf="center"
              display="flex"
              p={0}
            >
              <PersonAddIcon />
            </Box>
            <Box
              alignSelf="center"
              display="flex"
              p={1}
            >
              Sign up
            </Box>
          </Box>
        </Link>

      </Box>

    </HideAppBar>
  );
}

Header.defaultProp = {
  color: 'white'
};

Header.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'info',
    'success',
    'warning',
    'danger',
    'transparent',
    'white',
    'rose',
    'dark'
  ]),
  rightLinks: PropTypes.node,
  leftLinks: PropTypes.node,
  brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  // this will cause the sidebar to change the color from
  // props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    // color: PropTypes.oneOf([
    //   'primary',
    //   'info',
    //   'success',
    //   'warning',
    //   'danger',
    //   'transparent',
    //   'white',
    //   'rose',
    //   'dark'
    // ]).isRequired
  })
};
