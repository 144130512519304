import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';

import { Profile_theme_json, Profile_json_1 } from 'assets/jss/all_styles';

import { ButtonToProfil, ButtonToPayment } from './';

const useStyles = makeStyles(Profile_theme_json);

const Profile = props => {
  const { open } = props;
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
      style={Profile_json_1}>
      <Box p={1} alignSelf="center" flexGrow={1} flexShrink={1}>
        <ButtonToProfil
          classes={classes}
          open={open}
          email={props.email}
          avatar={props.avatar}
        />
        <ButtonToPayment
          classes={classes}
          open={open}
          point={props.point}
          avatar={props.avatar}
        />
      </Box>
    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
