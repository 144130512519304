import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import {
  TextField,
  Typography
} from '@material-ui/core';
import { SmallArea } from 'components';

const TitleBlock = props => {
  return (
    <SmallArea alias={'Title '} classes={props.classes}>
      <TextField
        size="small"
        // className={classes.textField}
        // subheader={<ListSubheader>Username</ListSubheader>}
        // label={"Title"}
        name="title"
        onChange={event => props.handleFieldChange('title', event.target.value)}
        value={props.values.title}
        type="text"
        variant="outlined"
        margin="dense"
        style={{ marginTop: '4px', marginBottom: '4px' }}
        // className={classes.textfieldformat}
      />
      {props.showTitleError && (
        <Typography
          // className={classes.fieldError}
          variant="body2">
          {props.errors.title[0]}
        </Typography>
      )}
    </SmallArea>
  );
};

TitleBlock.propTypes = {
  className: PropTypes.string
};

export default TitleBlock;
