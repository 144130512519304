import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { Divider } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import {
  SkeletonShadow_json_1,
  SkeletonShadow_json_2,
  SkeletonShadow_json_3,
  SkeletonShadow_json_4,
  SkeletonShadow_json_5,
  SkeletonShadow_json_6,
  SkeletonShadow_json_7,
} from 'assets/jss/all_styles';

const SkeletonShadow = props => {
  return (
    <div>
      <div style={SkeletonShadow_json_1}>
        <Box display="flex" p={2} style={SkeletonShadow_json_2} width="100%">
          <Box alignSelf="center" p={1}>
            <Skeleton
              animation="wave"
              height={40}
              variant="circle"
              width={40}
            />
          </Box>
          <Box alignSelf="center" flexGrow={1} flexShrink={1} p={1}>
            <Box display="flex" flexGrow={1} p={0}>
              <Box flexGrow={1} p={0.5} style={SkeletonShadow_json_3}>
                <Skeleton animation="wave" height={30} />
              </Box>
            </Box>
            <Box alignSelf="center" display="flex" flexGrow={1} p={0}>
              <Box flexGrow={1} p={0.5} style={SkeletonShadow_json_4}>
                <Skeleton animation="wave" height={30} />
              </Box>
            </Box>
          </Box>
          <Box alignSelf="center" display="flex" flexGrow={0} p={0}>
            <Box alignSelf="center" display="flex" flexGrow={0} p={1}>
              <Skeleton
                animation="wave"
                height={15}
                variant="circle"
                width={15}
              />
            </Box>
            <Box alignSelf="center" display="flex" flexGrow={0} p={1}>
              <Skeleton
                animation="wave"
                height={15}
                variant="circle"
                width={15}
              />
            </Box>
          </Box>
        </Box>

        <Divider className={props.classes.divider} />

        <Box display="flex" p={2} style={SkeletonShadow_json_2} width="100%">
          <Box alignSelf="center" p={1}>
            <Skeleton
              animation="wave"
              height={40}
              variant="circle"
              width={40}
            />
          </Box>
          <Box alignSelf="center" flexGrow={1} flexShrink={1} p={1}>
            <Box display="flex" flexGrow={1} p={0}>
              <Box flexGrow={1} p={0.5} style={SkeletonShadow_json_5}>
                <Skeleton animation="wave" height={30} />
              </Box>
            </Box>
            <Box alignSelf="center" display="flex" flexGrow={1} p={0}>
              <Box flexGrow={1} p={0.5} style={SkeletonShadow_json_6}>
                <Skeleton animation="wave" height={30} />
              </Box>
            </Box>
          </Box>
          <Box alignSelf="center" display="flex" flexGrow={0} p={0}>
            <Box alignSelf="center" display="flex" flexGrow={0} p={1}>
              <Skeleton
                animation="wave"
                height={15}
                variant="circle"
                width={15}
              />
            </Box>
            <Box alignSelf="center" display="flex" flexGrow={0} p={1}>
              <Skeleton
                animation="wave"
                height={15}
                variant="circle"
                width={15}
              />
            </Box>
          </Box>
        </Box>

        <Divider className={props.classes.divider} />

        <Box display="flex" p={2} style={SkeletonShadow_json_7} width="100%">
          <Box alignSelf="center" p={1}>
            <Skeleton
              animation="wave"
              height={40}
              variant="circle"
              width={40}
            />
          </Box>
          <Box alignSelf="center" flexGrow={1} flexShrink={1} p={1}>
            <Box display="flex" flexGrow={1} p={0}>
              <Box flexGrow={1} p={0.5} style={SkeletonShadow_json_5}>
                <Skeleton animation="wave" height={30} />
              </Box>
            </Box>
            <Box alignSelf="center" display="flex" flexGrow={1} p={0}>
              <Box flexGrow={1} p={0.5} style={SkeletonShadow_json_4}>
                <Skeleton animation="wave" height={30} />
              </Box>
            </Box>
          </Box>
          <Box alignSelf="center" display="flex" flexGrow={0} p={0}>
            <Box alignSelf="center" display="flex" flexGrow={0} p={1}>
              <Skeleton
                animation="wave"
                height={15}
                variant="circle"
                width={15}
              />
            </Box>
            <Box alignSelf="center" display="flex" flexGrow={0} p={1}>
              <Skeleton
                animation="wave"
                height={15}
                variant="circle"
                width={15}
              />
            </Box>
          </Box>
        </Box>
      </div>
    </div>
  );
};

SkeletonShadow.propTypes = {
  className: PropTypes.string
};

export default SkeletonShadow;
