import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Empty_theme_json } from 'assets/jss/all_styles';

const useStyles = makeStyles(Empty_theme_json);

const Empty = props => {
  const { children } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <main className={classes.content}>{children}</main>
    </div>
  );
};

Empty.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default Empty;
