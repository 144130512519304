
// import { Grid, Typography as MuiTypography } from '@material-ui/core';

//import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import ListItemText from '@material-ui/core/ListItemText';

// Component styles
import Box from '@material-ui/core/Box';
import {
Portlet,
PortletHeader,
PortletLabel,
PortletContent,
PortletFooter
} from 'components';

import List from '@material-ui/core/List';
import Hidden from '@material-ui/core/Hidden';
import {
Grid,
IconButton,
CircularProgress,
TextField,
Typography
} from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';


import { Proof__1 } from 'assets/jss/all_styles';

import React, { useEffect , useState} from 'react';
import { makeStyles } from '@material-ui/styles';
import { admin_style_json } from 'assets/jss/all_styles';

import { GET_SERVER } from 'common/get';
import { POST_SERVER } from 'common/post';
import { path } from 'common/path';

import { FilterByStatus, LineProofAdmin } from './components';
import VideocamIcon from '@material-ui/icons/Videocam';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';

import { HomeHeader, DividerInBox, SubTitleBar } from 'components';
import Paper from '@material-ui/core/Paper';


import { Button } from '@material-ui/core';
import { AvatarBlock, LineBlock, PauseBlock, ButonBlock } from './';
import {
with_commitment_line,
padding_commitment_line
} from 'assets/jss/all_styles';
import { Divider } from '@material-ui/core';

import {
BigArea,
SmallArea,
TextFieldShow,
IconeAndTextField
} from 'components';

import is_empty from 'common/is_empty';

import {
IconCommitment,
IconProofType,
IconStatus,
ShowCommitmentFile,
ShowProof
} from './components';

import { show_commitment_style } from 'assets/jss/all_styles';
import { Payment__3, Payment__8 } from 'assets/jss/all_styles';

import { home_1 } from 'assets/jss/all_styles';
import { useStyles_json } from 'assets/jss/all_styles';

import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core';



const  useStyles  = makeStyles(useStyles_json);





const Proof = props => {
const [formState, setFormState] = React.useState({
  proof: {},
  values: {
    proof_id: null
  }
});
// const useStyles = makeStyles(useStyles_json);
// const { classes } = this.props;
// const classes = useStyles();
const { classes} = props;
useEffect(() => {
  fillProofStateFromLocalStorage();
}, []);

const fillProofStateFromLocalStorage = async () => {
  setFormState(formState => ({
    ...formState,
    proof: JSON.parse(localStorage.getItem('proof'))
  }));
};

const handleVerification = (accept, decline, in_process) => {
  console.log('formState');
  console.log(formState);
  console.log('formState.proof');
  console.log(formState.proof);
  console.log('accept');
  console.log(accept);
  console.log('in_process');
  console.log(in_process);
  console.log('decline');
  console.log(decline);
  if (decline===true) {
    console.log('verification_failed; ' + formState.proof.decline);
    return 'failed';
  } else if (in_process===true) {
    console.log('verification_ongoing ' + formState.proof.in_process);
    return 'ongoing';
  } else if (accept===true) {
    console.log('verification_succe  ' + formState.proof.accept);
    return 'succes';
  } else {
    console.log(
      '§§§§§§§§§§§§§§§§§§§   handleVerification CRASH   §§§§§§§§§§§§§§§§§§§'
    );
  }
};

const handleDownloadFile = async proof_id_to_dowload => {
  try {
    const json_to_send = {
      input_values: {
        id: proof_id_to_dowload
      }
    };

    function saveBlob(blob, filename) {
      const downloadUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = filename;
      link.click();
      link.remove();
    }

    let path_download = path + 'back/proof_by_id';

    fetch(path_download, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(json_to_send)
    }).then(response => {
      response.headers.forEach(console.log);
      response.blob().then(blob => {
        saveBlob(
          blob,
          response.headers.get('content-disposition').split('=')[1]
        );
      });
    });
  } catch (error) {
    setFormState(formState => ({
      ...formState,
      isLoading: false,
      serviceError: error
    }));
  }
};

const handleAfficherProof = async () => {
  try {
    const { values } = formState;
    let path_root_flask_afficher_proof = path + 'back/afficher_proof';

    const var_from_POST_SERVER = await POST_SERVER(
      path_root_flask_afficher_proof,
      values
    );

    const proof = var_from_POST_SERVER['proof'];

    setFormState(formState => ({
      ...formState,
      proof: proof
    }));
  } catch (error) {
    setFormState(formState => ({
      ...formState,
      isLoading: false,
      serviceError: error
    }));
  }
};

return (

  <div style={Payment__3}>
  <HomeHeader
    title="Proof informations "
    quote_like_text="“Commitment leads to action. Action brings your dream closer.”"
  />
  <SubTitleBar subtitle="Evidence interface " />

  <Paper style={Payment__8}>
  <Box p={2}>
    <BigArea alias={'User informations'} >
            <SmallArea alias={'User ID '} >
              <TextFieldShow contenu={formState.proof.user} />
            </SmallArea> 
            <SmallArea alias={'Email '} classes={classes}>
              <TextFieldShow contenu={formState.proof.email} />
            </SmallArea>
          </BigArea>


          <DividerInBox />

          <BigArea alias={'Proof'} classes={classes}>
          <SmallArea alias={'Proof ID '} classes={classes}>
              <TextFieldShow contenu={formState.proof.id} />
            </SmallArea>
            <SmallArea alias={'Commitment ID '} classes={classes}>
              <TextFieldShow contenu={formState.proof.commitment} />
            </SmallArea>
            
            <SmallArea alias={'File of Proof'} classes={classes}>

              {!is_empty(formState.proof)        
              ? 
              <ShowProof proof={formState.proof} proof_type={formState.proof.proof_type} />
              : 
              <TextFieldShow contenu={'No proof is yet saved'} />  
              }

            </SmallArea>

            <SmallArea alias={'Type of the proof '} classes={classes}>
              <IconeAndTextField
                icone={<IconProofType proof_type={formState.proof.proof_type} />}
                text={formState.proof.proof_type}
              />
            </SmallArea>

            <SmallArea alias={'Verification '} classes={classes}>
              <TextFieldShow contenu={handleVerification(
                    formState.proof.accept,
                    formState.proof.decline,
                    formState.proof.in_process
                  )} />
            </SmallArea>

            <SmallArea alias={'Download proof file  '} classes={classes}>
              <IconeAndTextField
                icone={              <Button
                  className={classes.button}
                  variant="contained"
                  onClick={() => handleDownloadFile(formState.proof.id)}>
                  <CloudDownloadIcon />
                </Button>}
                text={"Download link"}
              />
            </SmallArea>

          </BigArea> 



    </Box>
  </Paper>
</div>
  
);
};

Proof.propTypes = {
className: PropTypes.string,
classes: PropTypes.object.isRequired,
history: PropTypes.object.isRequired
};

export default compose(
withRouter,
withStyles(show_commitment_style)
)(Proof);