import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import {
  Button
} from '@material-ui/core';

import { sign_up_style } from 'assets/jss/all_styles';

const useStyles = makeStyles(sign_up_style);

const ButtonSign = props => {
  const classes = useStyles();
  return (
    <Button
      align="center"
      className={classes.signInButton}
      disabled={!props.formState.isValid}
      disableElevation
      disableRipple={true}
      fullWidth
      inputlabelprops={{
        style: { color: '#fff' }
      }}
      size="large"
      type="submit"
      variant="contained">
      {props.text}
    </Button>
  );
};

ButtonSign.propTypes = {
  className: PropTypes.string
};

export default ButtonSign;

  