import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';

import { 
  SkeletonShadow, 
  NewCommitmentPanel, 
  CommitmentLine 
} from './';
import objectMap from 'common/objectMap';
import { useStyles_json } from 'assets/jss/all_styles';
import { makeStyles } from '@material-ui/styles';

const CommitmentLines = props => {
  const { history } = props;
  const useStyles = makeStyles(useStyles_json);
  const classes = useStyles();
  // console.log("classes")
  // console.log(classes)
  // console.log("classes")


  return (
    <Paper>
      {props.loading ? (
        <SkeletonShadow classes={classes} />
      ) : (
        <div>
          {Object.keys(props.commitments_of_user).length === 0 ? (
            <div>
              <NewCommitmentPanel
                interval_variable={props.interval_variable}
                classes={classes}
                history={history}
              />
            </div>
          ) : (
            Object.keys(props.commitments_of_user).map(commitment_order => (
              <CommitmentLine
              key={commitment_order}
              classes={classes}
                commitment_of_user={objectMap(
                  props.commitments_of_user[commitment_order]
                )}
                history={history}

              />
            ))
          )}
        </div>
      )}
    </Paper>
  );
};

CommitmentLines.propTypes = {
  className: PropTypes.string
};

export default CommitmentLines;
