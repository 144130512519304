import React from 'react';
import _ from 'underscore';
import Box from '@material-ui/core/Box';

import {
  TextFieldShow_json
} from 'assets/jss/all_styles';

const TextFieldShow = props => {
  return (
    <Box
      style={TextFieldShow_json}>
      {props.contenu}
    </Box>
  );
};

export default TextFieldShow;
