import React from 'react';

export default function Facebook(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 128 128"
      width="25px"
      height="25px">
      <path
        fill="#659ec9"
        d="M84.9,48.1H70.7v-9.3c0-3.5,2.3-4.3,4-4.3c1.6,0,10.1,0,10.1,0V19.1L70.8,19C55.5,19,52,30.5,52,37.9v10.3h-8.9V64H52c0,20.4,0,45,0,45h18.7c0,0,0-24.8,0-45h12.6L84.9,48.1z"
      />
      <path
        fill="#fff"
        d="M70.8,29l13.8,0.1v-10L70.8,19C55.5,19,52,30.5,52,37.9v10C52,40.5,55.5,29,70.8,29z"
      />
      <path
        fill="#444b54"
        d="M70.7,112H52c-1.7,0-3-1.3-3-3V67h-5.9c-1.7,0-3-1.3-3-3s1.3-3,3-3H52c1.7,0,3,1.3,3,3v42h12.7V64c0-1.7,1.3-3,3-3h9.9l1-9.9H70.7c-1.7,0-3-1.3-3-3v-9.3c0-5.1,3.5-7.3,7-7.3h7.1V22l-10.9,0C66.1,22,55,23.5,55,37.9v10.3c0,1.7-1.3,3-3,3h-8.9c-1.7,0-3-1.3-3-3s1.3-3,3-3H49v-7.3C49,27.8,54.7,16,70.8,16l13.9,0.1c1.7,0,3,1.3,3,3v15.4c0,1.7-1.3,3-3,3H74.6c-0.6,0-1,0-1,1.3v6.3h11.2c0.8,0,1.7,0.4,2.2,1c0.6,0.6,0.8,1.5,0.8,2.3l-1.6,15.9c-0.2,1.5-1.4,2.7-3,2.7h-9.6v42C73.7,110.7,72.3,112,70.7,112z"
      />
      <path
        fill="#444b54"
        d="M84.7 112H42c-1.7 0-3-1.3-3-3s1.3-3 3-3h42.7c1.7 0 3 1.3 3 3S86.3 112 84.7 112zM100.7 112c-.8 0-1.6-.3-2.1-.9-.6-.6-.9-1.3-.9-2.1 0-.2 0-.4.1-.6 0-.2.1-.4.2-.6.1-.2.2-.3.3-.5.1-.2.2-.3.4-.5 1.1-1.1 3.1-1.1 4.2 0 .6.6.9 1.3.9 2.1s-.3 1.6-.9 2.1C102.2 111.7 101.5 112 100.7 112z"
      />
    </svg>
  );
}
