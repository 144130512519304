import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import Box from '@material-ui/core/Box';
import {
  SubTitleBar_json
} from 'assets/jss/all_styles';

const SubTitleBar = props => {
  return (
    <Box
      p={0}
      display="flex"
      flexGrow={1}
      alignSelf="center"
      style={SubTitleBar_json}>
      <Box p={0} flexGrow={1} alignSelf="center">
        {props.subtitle}
      </Box>
      <Box
        p={0}
        justifyContent="flex-end"
        flexWrap="wrap"
        flexGrow={0}
        display="flex"
        alignSelf="center"></Box>
    </Box>
  );
};

// SubTitleBar.propTypes = {
//   className: PropTypes.string
// };

export default SubTitleBar;