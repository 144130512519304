import React, { Component } from 'react';
// Externals
import PropTypes from 'prop-types';
// Material helpers
import { withStyles } from '@material-ui/core';
// Custom components
import { AccountDetails } from './components';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import { HomeHeader, SubTitleBar } from 'components';

import { Profil_1, Payment__3, Payment__8 } from 'assets/jss/all_styles';
// import Profile from 'layouts/Main/components/Sidebar';
import { Profile } from 'layouts/Main/components/Sidebar/';

// Component styles
const styles = Profil_1;

class Profil extends Component {
  state = { tabIndex: 0 };

  render() {
    const { classes } = this.props;

    return (
      <div style={Payment__3}>
        <HomeHeader
          title="Personal informations "
          quote_like_text="“Commitment leads to action. Action brings your dream closer.”"
        />
        <SubTitleBar subtitle="Information about you " />

        <Paper style={Payment__8}>
          <div className={classes.root} >
            <AccountDetails history={this.props.history} />
          </div>
        </Paper>
      </div>
    );
  }
}

Profile.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Profil);
