import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import Box from '@material-ui/core/Box';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { Button, Typography } from '@material-ui/core';
import { useState, useEffect } from 'react';

import { SmallArea } from 'components';
import TermFormat from 'common/TermFormat';

import { FacebookProvider, Share } from 'react-facebook';

// import { GET_SERVER, POST_SERVER, path } from '../../../../../services/tools';
import { GET_SERVER } from 'common/get';
import { POST_SERVER } from 'common/post';
import { path } from 'common/path';

import { create_commitment_block_use_style } from 'assets/jss/all_styles';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(create_commitment_block_use_style)

const CreateCommitmentBlock = props => {

const classes = useStyles()


  const [VariableReceived_debug, SetVariableReceived_debug] = React.useState(
    null
  );
  const [url_image_to_share, Set_url_image_to_share] = React.useState(null);

  useEffect(() => {
    if (props.values.use_social_share) {
      GetUrlImageToShare(props);

      console.log('url_image_to_share');
      console.log(url_image_to_share);
      console.log('enf of : url_image_to_share');
    }
  }, [props.values.use_social_share]);

  const GetUrlImageToShare = async props => {
    let get_image_to_share_path = path + 'back/get_image_to_share';

    const var_from_POST_SERVER = await POST_SERVER(get_image_to_share_path, {
      theme: props.values.theme,
      number_sunflowers: props.values.number_sunflowers,
      title: props.values.title,
      remaining_time: TermFormat(props.values.end_date)
    });

    const image_to_share_url = var_from_POST_SERVER['image_to_share_url'];

    console.log('image_to_share_url');
    console.log(image_to_share_url);
    console.log('enf of : image_to_share_url');

    Set_url_image_to_share(image_to_share_url);

    // console.log(
    //   'https://localhost:3103/front/show_share_image/:' + url_image_to_share
    // );
    // console.log(
    //   'https://localhost:3103/front/show_share_image/:' + url_image_to_share
    // );
    // console.log(
    //   'https://localhost:3103/front/show_share_image/:' + url_image_to_share
    // );
  };

  const handleResponse = data => {};
  return (
    <SmallArea alias={''} classes={props.classes} onResponse={handleResponse}>
      {/* "https://c.pxhere.com/photos/db/d6/beautiful_beauty_bridge_california_clouds_contrast_footbridge_hd_wallpaper-1175820.jpg!d" */}
      {/* <Share href={ " https://mymethode.com/front/show_share_image?url=" + url_image_to_share }> */}
      <Share href={ url_image_to_share }>


      {/* <Share
        href={
          'https://127.0.0.1:3103/front/landing_page' 
        }> */}
      {/* <Share href={'https://127.0.0.1:3103/front/landing_page'}> */}
        {/* <Share
        href={
          'https://c.pxhere.com/photos/db/d6/beautiful_beauty_bridge_california_clouds_contrast_footbridge_hd_wallpaper-1175820.jpg!d'
        }> */}
        {({ handleClick, loading }) => (
          <Box>
          <Button
            className={classes.create_commitment_block}
            onClick={event => {
              if (props.values.use_social_share) {
                handleClick(event).then(variable => {
                  SetVariableReceived_debug(variable);
                  // console.log('variable');
                  // console.log(VariableReceived_debug);
                  props.handleCommit(event);
                });
              } else {
                props.handleCommit(event);
              }
            }}
            fullWidth
            disabled={!props.isValid}
            variant="outlined"
            // style={create_commitment_block}
            >
            Create a new commitment
          </Button>
          </Box>
        )}
      </Share>

      {/* <Button
        // className={props.classes.button}
        onClick={props.handleCommit}
        fullWidth
        disabled={!props.isValid}
        variant="outlined"
        style={create_commitment_block}>
        Create a new commitment
      </Button> */}
    </SmallArea>
  );
};

CreateCommitmentBlock.propTypes = {
  className: PropTypes.string
};

export default CreateCommitmentBlock;
