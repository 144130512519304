import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Button,
  Typography,
  Box
} from '@material-ui/core';
import { sign_up_style } from 'assets/jss/all_styles';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { Facebook as FacebookIcon } from 'icons';

const useStyles = makeStyles(sign_up_style);


const FacebookButton = props => {
  const classes = useStyles();
  return (
    <Grid container>
      <FacebookLogin
        appId="777721982806390"
        autoLoad={false}
        fields="name,email,picture,first_name,last_name"
        callback={props.responseFacebook}
        render={renderProps => (
          <Button
            className={classes.socialButtonsFacebook}
            align="center"
            fullWidth
            onClick={renderProps.onClick}
            size="small"
            // variant="text"
            variant="outlined">
            <Box display="flex" justifyContent="center" alignSelf="center">
              <Box alignSelf="center">
                <FacebookIcon className={classes.socialIcon} />
              </Box>
              <Box alignSelf="center">
                <Typography className={classes.textOfSocialButtons}>
                  Continue with Facebook{' '}
                </Typography>
              </Box>
            </Box>
          </Button>
        )}></FacebookLogin>
    </Grid>
  );
};

FacebookButton.propTypes = {
  className: PropTypes.string
};

export default FacebookButton;
