import PropTypes from 'prop-types';

import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography as MuiTypography } from '@material-ui/core';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import GetAppIcon from '@material-ui/icons/GetApp';
import Box from '@material-ui/core/Box';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import VideocamIcon from '@material-ui/icons/Videocam';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import Tooltip from '@material-ui/core/Tooltip';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import EventNoteIcon from '@material-ui/icons/EventNote';

// import { GET_SERVER, POST_SERVER, path } from '../../common';
import { GET_SERVER } from 'common/get';
import { POST_SERVER } from 'common/post';
import { path } from 'common/path';

import { Button } from '@material-ui/core';

const AvatarBlock = props => {
  const { order_of_proof, history, classes } = props;

  return (
    <Box alignSelf="center" p={1}>
      <Avatar
        className={props.colorPreuve(
          props.formState.formatted_proofs[order_of_proof],
          classes
        )}>
        {props.iconPreuve(props.formState.formatted_proofs[order_of_proof])}
      </Avatar>
    </Box>
  );
};

AvatarBlock.propTypes = {
  className: PropTypes.string
};

export default AvatarBlock;
