import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Link,
  Typography,
  Box
} from '@material-ui/core';
import { sign_up_style } from 'assets/jss/all_styles';

const useStyles = makeStyles(sign_up_style);

const HaveAccount = props => {
  const classes = useStyles();
  // Typography variant : ["h1","h2","h3","h4","h5","h6","subtitle1","subtitle2","body1","body2","caption","button","overline","srOnly","inherit"]
  return (
    <Box>
      <Typography align="center" color="textSecondary" variant="body1">
        {props.path==='/front/sign_up' ? 'Already have an account ?' : ''}
        {props.path==='/front/sign_in' ? "Don't have an account ?" : ''}
      </Typography>
      <Typography align="center"  >
        <Link
          className={classes.link}
          component={RouterLink}
          to={props.path==='/front/sign_up' ? '/front/sign_in' : '/front/sign_up'}
          variant="subtitle1">
          {props.path==='/front/sign_up' ? 'Connect =)' : ''}
          {props.path==='/front/sign_in' ? 'Sign up :)' : ''}
        </Link>
      </Typography>
    </Box>
  );
};

HaveAccount.propTypes = {
  className: PropTypes.string
};

export default HaveAccount;
