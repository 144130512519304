import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
// import { GET_SERVER, POST_SERVER, path } from '../../common';
import { GET_SERVER } from 'common/get';
import { POST_SERVER } from 'common/post';
import { path } from 'common/path';import { useState, useEffect } from 'react';
import sunflower from 'assets/img/icons8-sunflower-50.png';
import { Grid, Button, IconButton } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import Modal from 'react-awesome-modal';
import dino from 'assets/img/green_dino.png';
import basket from 'assets/img/basket_sunflore_illu_cut.jpg';
import { 
  WelcomePanel_json_1,
  WelcomePanel_json_2,
  WelcomePanel_json_3,
  WelcomePanel_json_4,
  WelcomePanel_json_5,
  WelcomePanel_json_6
 } from 'assets/jss/all_styles';

const WelcomePanel = props => {
  const [visible, setVisible] = React.useState(true);
  const [first_visit, setFirstVisit] = React.useState(false);

  useEffect(() => {
    handleFirstVisit();
  }, []);

  const handleFirstVisit = async () => {
    let first_visit_path = path + 'back/first_visit';
    const var_from_GET_SERVER = await GET_SERVER(first_visit_path);
    const first_visit = var_from_GET_SERVER['first_visit'];
    // console.log('first_visit : ' + first_visit);

    setFirstVisit(first_visit);

    return first_visit;
  };

  const closeModal = () => {
    setVisible(false);
  };
  return (
    <section>
      <Modal
        effect="fadeInUp"
        onClickAway={() => closeModal()}
        visible={first_visit && visible}
        //height="450"
        width="550">
        <Box p={1}>
          <Box
            alignSelf="center"
            display="flex"
            flexGrow={0}
            justifyContent="center"
            p={1}
            style={WelcomePanel_json_1}>
            <Typography variant="h2">Welcome in methode</Typography>
          </Box>

          <Box
            alignSelf="center"
            display="flex"
            flexGrow={1}
            justifyContent="center">
            <Box
              alignSelf="center"
              display="flex"
              flexGrow={1}
              justifyContent="flex-end">
              <img
                alt="Sunflower basket"
                src={basket}
                style={WelcomePanel_json_2}
                width="120px"
              />
            </Box>
            <Box
              alignSelf="center"
              flexGrow={1}
              justifyContent="center"
              style={WelcomePanel_json_1}>
              <span
                style={WelcomePanel_json_3}
              />
              <Box p={1}>
                <Typography>
                  Methode helps you to acheve your goals, we offer you for that
                  your first basket of{' '}
                  <span
                    style={WelcomePanel_json_3}>
                    <img
                      alt="Sunwill flower"
                      src={sunflower}
                      style={WelcomePanel_json_4}
                      width="20px"
                    />
                    Sunwill flower
                  </span>
                </Typography>
              </Box>

              <Box p={1}>
                <Typography>
                  Sunwill flowers can help you to persue your goals, they are
                  known to raise the will force of there user
                </Typography>
              </Box>

              <Box p={1}>
                <Typography>
                  Start by creating your first commitment.
                </Typography>
              </Box>
            </Box>
            <Box
              alignSelf="center"
              display="flex"
              flexGrow={1}
              justifyContent="flex-end">
              <img
                alt="Dinosor"
                src={dino}
                style={WelcomePanel_json_5}
                width="120px"
              />
            </Box>
          </Box>

          <Box
            alignSelf="center"
            display="flex"
            flexGrow={0}
            justifyContent="center">
            <Button // className={classes.button}
              fullWidth
              onClick={() => closeModal()}
              style={WelcomePanel_json_6}
              variant="outlined">
              Let's start !
            </Button>
          </Box>
        </Box>
      </Modal>
    </section>
  );
};

WelcomePanel.propTypes = {
  className: PropTypes.string
};

export default WelcomePanel;
