import React from 'react';
import PropTypes from 'prop-types';
import garden from 'assets/img/Asset 11.svg';
import PostAddIcon from '@material-ui/icons/PostAdd';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import {
  NewCommitmentPanel_json_1,
  NewCommitmentPanel_json_2,
  NewCommitmentPanel_json_3
} from 'assets/jss/all_styles';

const NewCommitmentPanel = props => {
  return (
    <Box p={3} flexWrap="wrap">
      <Box
        alignSelf="center"
        display="flex"
        flexGrow={1}
        justifyContent="center"
        p={0}
        style={NewCommitmentPanel_json_1}>
        <Typography style={NewCommitmentPanel_json_2} variant="h1">
          {'Hi :)'}
        </Typography>
      </Box>
      <Box
        alignSelf="center"
        display="flex"
        flexGrow={1}
        justifyContent="center"
        p={2}>
        <img 
          alt="Methode logo"
          src={garden}
          width="100%"
          height="250px"
        />
      </Box>
      <Box
        alignSelf="center"
        display="flex"
        flexGrow={1}
        justifyContent="center"
        flexWrap="wrap"
        p={0}
        style={NewCommitmentPanel_json_1}>
        <Typography align="center" variant="h3">
          {"Let's start by creating your first commitment ! "}
        </Typography>
      </Box>
      <Box
        alignSelf="center"
        display="flex"
        flexGrow={1}
        justifyContent="center"
        p={0}
        m={2}
        className={props.classes.newCommitment}>
        <Typography variant="h1">
          <Link
            component="button"
            variant="body2"
            className={props.classes.newCommitment}
            onClick={() => {
              // clearInterval(props.interval_variable);
              props.history.push({ pathname: '/front/append_commitment' });
            }}>
            <Box alignSelf="center" display="flex" flexGrow={0} p={1}>
              <Box alignSelf="center" display="flex" flexGrow={0} p={0.5}>
                <PostAddIcon
                  fontSize="large"
                  style={NewCommitmentPanel_json_2}
                />
              </Box>
              <Box
                alignSelf="center"
                display="flex"
                flexGrow={0}
                p={0.5}
                style={NewCommitmentPanel_json_3}
                // className={props.classes.newCommitment}
                >
                {' Create a new commitment'}
              </Box>
            </Box>
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};

NewCommitmentPanel.propTypes = {
  className: PropTypes.string
};

export default NewCommitmentPanel;
