import React from 'react';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import { makeStyles } from '@material-ui/core/styles';

import Header from 'components/Header/Header.js';
import Footer from 'components/Footer/Footer.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import HeaderLinks from 'components/Header/HeaderLinks.js';

import styles from 'assets/jss/material-kit-react/views/landingPage.js';

import mac_screen_full_no_background_crop_web from 'assets/img/mac_screen_full_no_background_crop_web.png';

import { HelmetMeta, ScreenShowImage } from './components';

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

const ShowShareImage = props => {
  let params = queryString.parse(props.location.search);

  const classes = useStyles();
  const { ...rest } = props;

  return (
    <div>
      <HelmetMeta />
      <Header
        brand="Accomplish what you want"
        changeColorOnScroll={{
          height: 400,
          color: '#ffffff'
        }}
        fixed
        rightLinks={<HeaderLinks />}
        routes={dashboardRoutes}
        {...rest}
      />
      <meta property="og:image"              content="http://static01.nyt.com/images/2015/02/19/arts/international/19iht-btnumbers19A/19iht-btnumbers19A-facebookJumbo-v2.jpg" />
      <ScreenShowImage
        params={params}
        classes={classes}
        title="Build the things you want"
        text_introduction="Chose what you want to realize and bind your self to achieve it,
          beware that not following your own commitment have consequences :
          the ones you had chosen"
        subtitle="Method helps you accomplish the things that matters for you."
        button_text="Sign up for free"
        screen_image={mac_screen_full_no_background_crop_web}
      />
      <Footer />
    </div>
  );
};

export default withRouter(ShowShareImage);