


import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Typography
} from '@material-ui/core';

import { sign_up_style } from 'assets/jss/all_styles';

const useStyles = makeStyles(sign_up_style);

const SubTitle = props => {
  const classes = useStyles();
  return (
    <Typography
      align="center"
      className={classes.subtitle}
      gutterBottom
      variant="subtitle1">
      {props.subtitle}
    </Typography>
  );
};

SubTitle.propTypes = {
  className: PropTypes.string
};

export default SubTitle;
