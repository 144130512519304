import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import Box from '@material-ui/core/Box';
import { styles } from 'assets/jss/all_styles';

const SmallArea = props => {
  return (
    <Box width={'100%'}>
      <Box className={styles.aliasright}>{props.alias}</Box>
      <Box>{props.children}</Box>
    </Box>
  );
};

export default SmallArea