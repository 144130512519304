import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import _ from 'underscore';
import {
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  // IconButton,
  TextField,
  Typography
} from '@material-ui/core';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import PeopleIcon from '@material-ui/icons/People';
import SchoolIcon from '@material-ui/icons/School';
import DateFnsUtils from '@date-io/date-fns';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { DropzoneArea } from 'material-ui-dropzone';
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
  KeyboardTimePicker,
  DateTimePicker,
  KeyboardDatePicker
} from '@material-ui/pickers';
import { Dashboard as DashboardLayout } from 'layouts';
import { green } from '@material-ui/core/colors';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import PostAddIcon from '@material-ui/icons/PostAdd';
// import { makeStyles } from '@material-ui/core/styles';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';
import SportsBaseballIcon from '@material-ui/icons/SportsBaseball';
import MicIcon from '@material-ui/icons/Mic';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { FileInput, SVGIcon } from 'react-md';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { Divider, Drawer } from '@material-ui/core';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import PhoneRoundedIcon from '@material-ui/icons/PhoneRounded';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import sunflower from 'assets/img/icons8-sunflower-50.png';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Input from '@material-ui/core/Input';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles, createTheme } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import {
  BigArea,
  SmallArea,
  MenuItemWithIcon,
} from 'components';
import { ChoiceSunflowers, NumberSunflowers, ChoiceSocial } from './';

const MotivationInformation = props => {
  const { classes } = props;
  return (
    <BigArea
      alias={
        <Box display="flex" alignSelf="center">
          <Box alignSelf="center">Motivation</Box>
          <Box p={1}></Box>
          <Box alignSelf="center">
            <img alt="Sunflowers" width="34px" src={sunflower} />
          </Box>
        </Box>
      }
      classes={classes}>
      <ChoiceSocial
        classes={classes}
        handleFieldChange={props.handleFieldChange}
      />
      <ChoiceSunflowers
        classes={classes}
        handleFieldChange={props.handleFieldChange}
      />
      <NumberSunflowers
        classes={classes}
        values={props.values}
        errors={props.errors}
        showNumberSunflowersError={props.showNumberSunflowersError}
        handleFieldChange={props.handleFieldChange}
      />
    </BigArea>
  );
};

MotivationInformation.propTypes = {
  className: PropTypes.string
};

export default MotivationInformation;
