import _ from 'underscore';
import PropTypes from 'prop-types';
import {  createTheme } from '@material-ui/core/styles';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import { drawerWidth } from 'assets/jss/material-kit-react.js';
import { colors } from '@material-ui/core';
import { red, green, blue } from '@mui/material/colors';

const useStyles_json = theme => ({
  root: {
    padding: theme.spacing(1),
    backgroundImage: 'url(/images/icon_ecarte_5.jpg)',
    backgroundSize: '40%'
  },
  paper: {
    // padding: theme.spacing(5),
    margin: 'auto',
    padding: '62.12px 0px 48px'
  },
  summary: {
    backgroundColor: '#fff'
  },
  cadre: {
    padding: theme.spacing(2)
  },
  green: {
    color: '#fff',
    backgroundColor: '#a2f5a6',
    margin: theme.spacing(1)
  },
  red: {
    color: '#fff',
    backgroundColor: '#ff8282',
    margin: theme.spacing(1)
  },
  blue: {
    color: '#fff',
    backgroundColor: '#c2ddfc',
    margin: theme.spacing(1)
  },
  newCommitment: {
    color: '#f08c00',
    '&:hover': {
      color: '#f0b400'
    }
  },
  shape: {
    backgroundColor: theme.palette.primary.main,
    width: 40,
    height: 40
  },
  shapeCircle: {
    borderRadius: '50%'
  },
  divider: {
    margin: '0 16px 0 75px' //theme.spacing(0, 2)
  },
  statusIcone: {
    backgroundColor: '#ffffff', //'#f4f6f8',
    borderRadius: '30%',
    border: '1px solid rgba(230, 230, 230, 1)'
  },
  themeIcone: {
    backgroundColor: '#ffffff', //' rgba(220, 220, 220, 1)',
    padding: '5px',
    borderRadius: '30%'
  },
  newhover: {
    // boxShadow: 'none',
    // textTransform: 'none',
    // fontSize: 16,
    // padding: '6px 12px',
    // border: '1px solid',
    // lineHeight: 1.5,
    // backgroundColor: '#0063cc',
    // borderColor: '#0063cc',
    // fontFamily: [
    //   '-apple-system',
    //   'BlinkMacSystemFont',
    //   '"Segoe UI"',
    //   'Roboto',
    //   '"Helvetica Neue"',
    //   'Arial',
    //   'sans-serif',
    //   '"Apple Color Emoji"',
    //   '"Segoe UI Emoji"',
    //   '"Segoe UI Symbol"',
    // ].join(','),
    '&:hover': {
      backgroundColor: '#dedede'
      //   borderColor: '#0062cc',
      //   boxShadow: 'none',
    }
    // '&:active': {
    //   boxShadow: 'none',
    //   backgroundColor: '#0062cc',
    //   borderColor: '#005cbf',
    // },
    // '&:focus': {
    //   boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    // },
  }
});
const styles_append_commitment_json = theme => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
      backgroundColor: green[50],
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4),
      backgroundColor: green[100],
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4),
      backgroundColor: green[150],
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(8),
      backgroundColor: green[200],
    },
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(8),
      backgroundColor: green[250],
    },
  },
  paper: {
    // padding: theme.spacing(5),
    margin: 'auto',
    padding: '62.12px 0px 48px'
  },
  summary: {
    backgroundColor: '#fff'
  },
  cadre: {
    padding: theme.spacing(2)
  },
  green: {
    color: '#fff',
    backgroundColor: '#a2f5a6',
    margin: theme.spacing(1)
  },
  red: {
    color: '#fff',
    backgroundColor: '#ff8282',
    margin: theme.spacing(1)
  },
  blue: {
    color: '#fff',
    backgroundColor: '#c2ddfc',
    margin: theme.spacing(1)
  },
  newCommitment: {
    color: '#f08c00',
    '&:hover': {
      color: '#f0b400'
    }
  },
  shape: {
    backgroundColor: theme.palette.primary.main,
    width: 40,
    height: 40
  },
  shapeCircle: {
    borderRadius: '50%'
  },
  divider: {
    margin: '0 16px 0 75px' //theme.spacing(0, 2)
  },
  statusIcone: {
    backgroundColor: '#f4f6f8',
    borderRadius: '30%',
    border: '1px solid rgba(200, 200, 200, 1)'
  },
  themeIcone: {
    backgroundColor: ' rgba(220, 220, 220, 1)',
    borderRadius: '30%'
  },
  aliasleft: {
    lineHeight: '1.41666667',
    fontStyle: 'normal',
    fontFamily:
      "helvetica, arial, sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    fontWeight: '700',
    // fontSize: '1em',
    // fontSize: '1em',
    float: 'left',
    fontSize: '1rem',
    color: '#3b3b3b'
    // backgroundColor: '#005500',
  },
  aliasright: {
    paddingBottom: '0px',
    paddingTop: '8px',
    // backgroundColor: '#000055',
    // display: 'inline-block',
    lineHeight: '1.41666667',
    // fontSize: '1em',
    // fontSize: '1rem',
    color: '#747474',
    fontStyle: 'normal',
    fontFamily:
      "helvetica, arial, sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    fontWeight: '400',
    // fontSize: '1em',
    // fontSize: '1em',
    // float: 'left',
    fontSize: '0.8em'
  },
  textfieldformat: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '6px 12px 6px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    lineHeight: '19px',
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  }
  // selectform: {
  // 	borderRadius: 4,
  // 	position: 'relative',
  // 	backgroundColor: theme.palette.background.paper,
  // 	border: '1px solid #ced4da',
  // 	fontSize: 16,
  // 	padding: '10px 26px 10px 12px',
  // 	transition: theme.transitions.create(['border-color', 'box-shadow']),
  // 	// Use the system font instead of the default Roboto font.
  // 	fontFamily: [
  // 	  '-apple-system',
  // 	  'BlinkMacSystemFont',
  // 	  '"Segoe UI"',
  // 	  'Roboto',
  // 	  '"Helvetica Neue"',
  // 	  'Arial',
  // 	  'sans-serif',
  // 	  '"Apple Color Emoji"',
  // 	  '"Segoe UI Emoji"',
  // 	  '"Segoe UI Symbol"',
  // 	].join(','),
  // 	'&:focus': {
  // 	  borderRadius: 4,
  // 	  borderColor: '#80bdff',
  // 	  boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
  // 	},
  // }
});
const BootstrapInput_json = theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3)
    }
  },
  input: {
    //   borderRadius: 4,
    position: 'relative',
    //   backgroundColor: theme.palette.background.paper,
    //   border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:focus': {
      // borderRadius: 4,
      backgroundColor: ' rgba(0,0,0,0) '

      // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    }
  }
});
const useStyles_appendproof_json = theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/auth.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  socialButtons: {
    marginTop: theme.spacing(3)
  },
  socialIcon: {
    marginRight: theme.spacing(1)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  },
  divider: {
    margin: '0 16px 0 75px' //theme.spacing(0, 2)
  },
  aliasleft: {
    lineHeight: '1.41666667',
    fontStyle: 'normal',
    fontFamily:
      "helvetica, arial, sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    fontWeight: '700',
    // fontSize: '1em',
    // fontSize: '1em',
    float: 'left',
    fontSize: '1rem',
    color: '#3b3b3b'
    // backgroundColor: '#005500',
  },
  aliasright: {
    paddingBottom: '0px',
    paddingTop: '8px',
    // backgroundColor: '#000055',
    // display: 'inline-block',
    lineHeight: '1.41666667',
    // fontSize: '1em',
    // fontSize: '1rem',
    color: '#747474',
    fontStyle: 'normal',
    fontFamily:
      "helvetica, arial, sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    fontWeight: '400',
    // fontSize: '1em',
    // fontSize: '1em',
    // float: 'left',
    fontSize: '0.8em',
    paddingLeft: '8px'
  }
});
const myTheme_json = {
  palette: {
    primary: {
      main: '#00a82d'
    }
  }
};
const admin_style_json = theme => ({
  root: {
    padding: theme.spacing(1)
  },
  green: {
    color: '#fff',
    backgroundColor: '#a2f5a6'
  },
  red: {
    color: '#fff',
    backgroundColor: '#ff8282'
  },
  blue: {
    color: '#fff',
    backgroundColor: '#c2ddfc'
  }
});
const show_commitment_style = theme => ({
  root: {
    padding: theme.spacing(4),
    backgroundImage: 'url(/images/icon_ecarte_5.jpg)',
    backgroundSize: '40%'
  },
  rot: {
    padding: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    flex: '1 0 auto',
    margin: theme.spacing(1)
  },
  divider: {
    margin: '0 16px 0 75px' //theme.spacing(0, 2)
  },
  aliasleft: {
    lineHeight: '1.41666667',
    fontStyle: 'normal',
    fontFamily:
      "helvetica, arial, sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    fontWeight: '700',
    // fontSize: '1em',
    // fontSize: '1em',
    float: 'left',
    fontSize: '1rem',
    color: '#3b3b3b'
    // backgroundColor: '#005500',
  },
  aliasright: {
    paddingBottom: '0px',
    paddingTop: '8px',
    // backgroundColor: '#000055',
    // display: 'inline-block',
    lineHeight: '1.41666667',
    // fontSize: '1em',
    // fontSize: '1rem',
    color: '#747474',
    fontStyle: 'normal',
    fontFamily:
      "helvetica, arial, sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    fontWeight: '400',
    // fontSize: '1em',
    // fontSize: '1em',
    // float: 'left',
    fontSize: '0.8em',
    paddingLeft: '8px'
  },
  textfieldformat: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '6px 12px 6px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    lineHeight: '19px',
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  }
});
const sign_in_style = theme => ({
  root: {
    height: '100%',
    backgroundImage: 'url(/images/icon_ecarte_5.jpg)',
    backgroundSize: '40%'
  },
  paper: {
    // padding: theme.spacing(10),
    margin: 'auto',
    padding: '62.12px 0px 48px',
    maxWidth: 512,
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%'
    }
  },
  grid: {
    height: '100%'
  },
  subtitle: {
    marginBottom: '40px',
    marginTop: '15px',
    color: '#676767'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/auth.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  content: {
    height: '100%',
    display: 'flex'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    flexBasis: 700,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    }
  },
  horizontalRow: {
    position: 'relative',
    marginBottom: '18px',
    marginTop: '17px'
  },
  horizontalText: {
    width: '100%',
    textAlign: 'center',
    backgroundColor: '#ffffff',
    position: 'relative',
    color: '#ababab',
    fontSize: '14px',
    zIndex: '1',
    overflow: 'hidden',
    '&::after': {
      width: '50%',
      top: '51%',
      overflow: 'hidden',
      height: '1px',
      backgroundColor: '#d0d0d0',
      content: "'U+002D'",
      position: 'absolute',
      marginLeft: '2%'
    },
    '&::before': {
      width: '50%',
      top: '51%',
      overflow: 'hidden',
      height: '1px',
      backgroundColor: '#d0d0d0',
      content: "'U+002D'",
      position: 'absolute',
      marginLeft: '-52%',
      textAlign: 'right'
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  socialButtonsFacebook: {
    height: '40px',
    border: 'solid 5px linear-gradient(45deg, #274986 30%, #4e69aa 90%)',
    borderRadius: '7px',
    color: 'white',
    textTransform: 'none',
    marginTop: theme.spacing(1)
  },
  socialButtonsGoogle: {
    height: '40px',
    borderColor: 'linear-gradient(45deg, #de5246 30%, #ffce44 90%)',
    borderRadius: '7px',
    color: 'white',
    textTransform: 'none',
    marginTop: theme.spacing(1)
  },
  socialIcon: {
    marginRight: theme.spacing(1)
  },
  socialTex: {
    color: '#6a6a6a'
  },
  textOfSocialButtons: {
    paddingLeft: '10px',
    color: '#6a6a6a'
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(1)
  },
  signInButton: {
    margin: theme.spacing(2, 0),
    color: '#ffffff',
    textTransform: 'none',
    backgroundColor: '#f08c00',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:hover': {
      backgroundColor: '#FFAA33',
      color: '#ffffff'
    },
    '&:active': {
      backgroundColor: '#f08c00'
    },
    '&:focus': {
      boxShadow: '0 0 0 0.1rem #FFBB5C',
      color: '#ffffff'
    },
    '&:disabled': {
      backgroundColor: '#a05a00',
      color: '#eeeeee'
    }
  },
  link: {
    color: '#05a82d',
    '&:hover': {
      textDecoration: 'none'
    }
  }
});
const sign_up_style = sign_in_style;

const create_commitment_block_use_style = {
  create_commitment_block: {
    backgroundColor: '#059f2b',
    color: '#ffffff',
    textTransform: 'capitalize',
    marginTop: '4px',
    marginBottom: '4px',

    '&:hover': {
      backgroundColor: '#2ecf5b',
      color: '#ffffff'
    },
    "&:disabled": {
      backgroundColor: '#036f1b',
      color: '#ffffff'
    },
  }
};
const initial_information_block = {
  backgroundColor: '#6c0c15',
  color: '#ffffff'
};
const choice_social_icon = { fill: '#6c0c15' };
const choice_social_input = { color: '#6c0c15' };
const choice_sunflowers_icon = { fill: '#00a82d' };
const choice_sunflowers_input = { color: '#00a82d' };
const number_sunflowers_input = { marginTop: '4px', marginBottom: '4px' };
const proof_description = number_sunflowers_input;
const proof_type_proof = number_sunflowers_input;
const proof_description_button = {
  backgroundColor: '#000000',
  color: '#ffffff',
  textTransform: 'none'
};
const date_term = {
  marginTop: '4px',
  marginBottom: '4px',
  color: '#000000'
};
const padding_append_proof_1 = { padding: '30px' };
const padding_append_proof_2 = { maxWidth: '738px' };
const confirm_proof = {
  backgroundColor: '#00a82d',
  color: '#ffffff',
  textTransform: 'none'
};
const home_1 = padding_append_proof_1;
const with_commitment_line = { width: '100%' };
const padding_commitment_line = { paddingBottom: '8px', paddingTop: '8px' };
const corps_commitment_line_font = { fontSize: 14 };

export { useStyles_json };
export { myTheme_json };
export { useStyles_appendproof_json };

const BootstrapInput = withStyles(BootstrapInput_json)(InputBase);
const styles = styles_append_commitment_json;
const corps_commitment_line_vertcial = { verticalAlign: 'middle' };
const corps_commitment_line_typography = {
  textTransform: 'none',
  display: 'flex',
  alignItems: 'center'
};
const corps_commitment_line_pad = { paddingTop: '4px', paddingBottom: '0px' };
const dynamic_icon_theme = { marginTop: '12px', marginLeft: '12px' };
const IconProofType_json_1 = { marginLeft: '16px' };
const NewCommitmentPanel_json_1 = {
  fontSize: '20px',
  height: '80px',
  fontFamily: 'caption',
  color: '#f08c00',
  fontColor: '#f08c00'
};
const NewCommitmentPanel_json_2 = {
  fontSize: '1em' 
  // '60px'
  // color: '#f08c00',
  // fontColor: '#f08c00'
};
const NewCommitmentPanel_json_3 = {
  fontSize: '20px',
  verticalAlign: 'middle',
  color: '#f08c00',
  fontColor: '#f08c00'
};
const SkeletonShadow_json_1 = with_commitment_line;
const SkeletonShadow_json_2 = { paddingBottom: '8px', paddingTop: '8px' };
const SkeletonShadow_json_3 = { paddingBottom: '0px', paddingTop: '0px' };
const SkeletonShadow_json_4 = { paddingTop: '4px', paddingBottom: '0px' };
const SkeletonShadow_json_5 = { paddingTop: '0px', paddingBottom: '0px' };
const SkeletonShadow_json_6 = SkeletonShadow_json_4;
const SkeletonShadow_json_7 = SkeletonShadow_json_2;
const CommitmentsBar_json_1 = { height: '80px', fontFamily: '-apple-system' };
const CommitmentsBar_json_2 = {
  height: '24px',
  marginTop: '10px',
  marginBottom: '10px',
  fontFamily: '-apple-system'
};
const CommitmentsBar_json_3 = { fontSize: '15px' };
const CommitmentsBar_json_4 = { fontSize: '20px' };
const CommitmentToolsBar_json_1 = theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3)
    }
  },
  input: {
    //   borderRadius: 4,
    position: 'relative',
    //   backgroundColor: theme.palette.background.paper,
    //   border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:focus': {
      // borderRadius: 4,
      backgroundColor: ' rgba(0,0,0,0) '

      // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    }
  }
});
const CommitmentToolsBar_json_2 = {
  fontSize: '15px',
  height: '24px',
  marginTop: '10px',
  marginBottom: '10px',
  fontFamily: '-apple-system'
};
const TipsBar_json_1 = {
  fontSize: '15px',
  marginTop: '10px',
  marginBottom: '10px',
  fontFamily: 'monospace',
  color: '#ffffff',
  backgroundColor: '#00a82d',
  borderRadius: '7px',
  fontWeight: '800'
};
const WelcomePanel_json_1 = { fontFamily: '-apple-system' };
const WelcomePanel_json_2 = { marginRight: '8px', marginLeft: '16px' };
const WelcomePanel_json_3 = {
  color: '#00a82d',
  fontWeight: 800,
  fontFamily: '-apple-system'
};
const WelcomePanel_json_4 = { verticalAlign: 'middle' };
const WelcomePanel_json_5 = WelcomePanel_json_2;
const WelcomePanel_json_6 = {
  backgroundColor: '#059f2b',
  color: '#ffffff',
  textTransform: 'none',
  marginTop: '4px',
  marginBottom: '4px'
};
const NotFound__1 = theme => ({
  root: {
    padding: theme.spacing(4)
  },
  content: {
    paddingTop: 150,
    textAlign: 'center'
  },
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 560
  }
});
const TextFieldShow_json_1 = {
  paddingTop: '8px',
  paddingBottom: '16px',
  paddingLeft: '8px'
};
const Payment__1 = theme => ({
  root: {
    padding: theme.spacing(4)
  },
  rot: {
    padding: theme.spacing(2)
  },
  divider: {
    margin: '0 16px 0 75px' //theme.spacing(0, 2)
  },
  aliasleft: {
    lineHeight: '1.41666667',
    fontStyle: 'normal',
    fontFamily:
      "helvetica, arial, sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    fontWeight: '700',
    // fontSize: '1em',
    // fontSize: '1em',
    float: 'left',
    fontSize: '1rem',
    color: '#3b3b3b'
    // backgroundColor: '#005500',
  },
  aliasright: {
    paddingBottom: '0px',
    paddingTop: '8px',
    // backgroundColor: '#000055',
    // display: 'inline-block',
    lineHeight: '1.41666667',
    // fontSize: '1em',
    // fontSize: '1rem',
    color: '#747474',
    fontStyle: 'normal',
    fontFamily:
      "helvetica, arial, sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    fontWeight: '400',
    // fontSize: '1em',
    // fontSize: '1em',
    // float: 'left',
    fontSize: '0.8em',
    paddingLeft: '8px'
  }
});
const Payment__2 = {
  backgroundColor: '#00a82d',
  color: '#ffffff',
  textTransform: 'none'
};
const Payment__3 = padding_append_proof_1;
const Payment__4 = {
  fontSize: '20px',
  height: '80px',
  fontFamily: '-apple-system'
};
const Payment__5 = CommitmentsBar_json_1;
const Payment__6 = { fontSize: '16px' };
const Payment__7 = {
  fontSize: '15px',
  height: '24px',
  marginTop: '10px',
  marginBottom: '10px',
  fontFamily: '-apple-system'
};
const Payment__8 = padding_append_proof_2;
const Payment__9 = {
  width: '100%',
  paddingTop: '8px',
  paddingBottom: '8px'
};
const Proof__1 = theme => ({
  root: {
    padding: theme.spacing(4)
  },
  rot: {
    padding: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    flex: '1 0 auto',
    margin: theme.spacing(1)
  }
});

const Profil_1 = theme => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4),
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(8),
    },
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(8),
    },
  }

});
const AccountDetails_1 = theme => ({
  media: {
    height: '10em',
    paddingTop: '56.25%' // 16:9
  },
  rot: {
    padding: theme.spacing(2)
  },
  root: {
    padding: theme.spacing(4)
  },
  divider: {
    margin: '0 16px 0 75px' //theme.spacing(0, 2)
  },
  aliasleft: {
    lineHeight: '1.41666667',
    fontStyle: 'normal',
    fontFamily:
      "helvetica, arial, sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    fontWeight: '700',
    // fontSize: '1em',
    // fontSize: '1em',
    float: 'left',
    fontSize: '1rem',
    color: '#3b3b3b'
    // backgroundColor: '#005500',
  },
  aliasright: {
    paddingBottom: '0px',
    paddingTop: '8px',
    // backgroundColor: '#000055',
    // display: 'inline-block',
    lineHeight: '1.41666667',
    // fontSize: '1em',
    // fontSize: '1rem',
    color: '#747474',
    fontStyle: 'normal',
    fontFamily:
      "helvetica, arial, sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    fontWeight: '400',
    // fontSize: '1em',
    // fontSize: '1em',
    // float: 'left',
    fontSize: '0.8em',
    paddingLeft: '8px'
  }
});
const AccountDetails_2 = { padding: '10px', width: '200px' };
const AccountDetails_3 = { verticalAlign: 'middle' };
const DownloadFile_4 = {
  backgroundColor: '#000000',
  color: '#ffffff',
  textTransform: 'none'
};
const DownloadFile_5 = { color: '#ffffff', textTransform: 'none' };
const DownloadFile_6 = dynamic_icon_theme;
const ShowCommitmentFile_1 = { maxWidth: 345 };
const ShowCommitmentFile_2 = { height: 250 };

const Sidebar_theme_json = theme => ({
  root: {
    display: 'flex'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    //marginRight: 16,
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    width: drawerWidth,
    //backgroundColor: '#000000',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    // backgroundColor: '#000000',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: '61px', //theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: '61px' //theme.spacing(9) + 1,
    }
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
});
const Profile_theme_json = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: 44,
    height: 44
  },
  name: {
    marginTop: theme.spacing(1)
  }
});

export { admin_style_json };
export { BootstrapInput };
export { styles };
export { show_commitment_style };
export { sign_in_style };
export { sign_up_style };
export { create_commitment_block_use_style };
export { initial_information_block };
export { dynamic_icon_theme };
export { IconProofType_json_1 };
export { corps_commitment_line_pad };
export { corps_commitment_line_typography };
export { corps_commitment_line_vertcial };

export { choice_social_icon };
export { choice_social_input };
export { choice_sunflowers_icon };
export { choice_sunflowers_input };
export { number_sunflowers_input };
export { proof_description };
export { proof_type_proof };
export { proof_description_button };
export { date_term };
export { padding_append_proof_1 };
export { padding_append_proof_2 };
export { confirm_proof };
export { home_1 };
export { with_commitment_line };
export { padding_commitment_line };
export { corps_commitment_line_font };

export { NewCommitmentPanel_json_1 };
export { NewCommitmentPanel_json_2 };
export { NewCommitmentPanel_json_3 };
export { SkeletonShadow_json_1 };
export { SkeletonShadow_json_2 };
export { SkeletonShadow_json_3 };
export { SkeletonShadow_json_4 };
export { SkeletonShadow_json_5 };
export { SkeletonShadow_json_6 };
export { SkeletonShadow_json_7 };
export { CommitmentsBar_json_1 };
export { CommitmentsBar_json_2 };
export { CommitmentsBar_json_3 };
export { CommitmentsBar_json_4 };
export { CommitmentToolsBar_json_1 };
export { CommitmentToolsBar_json_2 };
export { TipsBar_json_1 };
export { WelcomePanel_json_1 };
export { WelcomePanel_json_2 };
export { WelcomePanel_json_3 };
export { WelcomePanel_json_4 };
export { WelcomePanel_json_5 };
export { WelcomePanel_json_6 };
export { NotFound__1 };
export { TextFieldShow_json_1 };
export { Payment__1 };
export { Payment__2 };
export { Payment__3 };
export { Payment__4 };
export { Payment__5 };
export { Payment__6 };
export { Payment__7 };
export { Payment__8 };
export { Payment__9 };
export { Proof__1 };
export { Profil_1 };
export { AccountDetails_1 };
export { AccountDetails_2 };
export { AccountDetails_3 };
export { DownloadFile_4 };
export { DownloadFile_5 };
export { DownloadFile_6 };
export { ShowCommitmentFile_1 };
export { ShowCommitmentFile_2 };

export { Sidebar_theme_json };
export { Profile_theme_json };

const Profile_json_1 = { display: 'block' };
const Profile_json_2 = { paddingLeft: 0 };
const Profile_json_3 = { padding: 0, justifyContent: 'center' };
const Profile_json_4 = { fontSize: '15px', color: '#444444' };
const Profile_json_5 = { fontSize: '15px' };
const Profile_json_6 = { verticalAlign: 'middle' };
const Profile_json_7 = {
  backgroundColor: '#059f2b',
  color: '#ffffff',
  textTransform: 'none',
  fontSize: '12px',
  padding: '8px'
};

export { Profile_json_1 };
export { Profile_json_2 };
export { Profile_json_3 };
export { Profile_json_4 };
export { Profile_json_5 };
export { Profile_json_6 };
export { Profile_json_7 };

const Topbar_theme_json = theme => ({
  root: {
    boxShadow: 'none'
  }
});

export { Topbar_theme_json };

const Minimal_theme_json = () => ({
  root: {
    paddingTop: 64,
    height: '100%'
  },
  content: {
    height: '100%'
  }
});

export { Minimal_theme_json };

const Empty_theme_json = () => ({
  root: {
    paddingTop: 0,
    height: '100%'
  },
  content: {
    height: '100%'
  }
});

export { Empty_theme_json };

const Main_theme_json = theme => ({
  root: {
    display: 'flex'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 16
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: '60px',
    // width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1
    }
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1
    // padding: theme.spacing(3),
  }
});

export { Main_theme_json };
const Footer_theme_json = theme => ({
  root: {
    padding: theme.spacing(4),
    bottom: 0
  }
});
export { Footer_theme_json };

const Topbar_theme_json_2 = theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  }
});
export { Topbar_theme_json_2 };

const SidebarNav_json = { paddingLeft: '24px', paddingRight: '16px' };

export { SidebarNav_json };

const SidebarNav_json_2 = { paddingLeft: '14px', paddingRight: '8px' };
export { SidebarNav_json_2 };

const SidebarNav_theme_json = theme => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  },
  icon: {
    color: '#000000', //theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    // marginRight: theme.spacing(1),
    paddingLeft: '8px',
    paddingRight: '8px'
  },
  active: {
    color: '#f08c00', //theme.palette.primary.main
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: '#f08c00' //theme.palette.primary.main
    }
  }
});
export { SidebarNav_theme_json };
const TextFieldShow_json = {
  paddingTop: '8px',
  paddingBottom: '16px',
  paddingLeft: '8px'
};
export { TextFieldShow_json };
const SubTitleBar_json = {
  fontSize: '15px',
  height: '24px',
  marginTop: '10px',
  marginBottom: '10px',
  fontFamily: '-apple-system'
};
export { SubTitleBar_json };
const IconeAndTextField_json = { paddingTop: '8px' };
export { IconeAndTextField_json };

const HomeHeader_json_1 = {
  fontSize: '20px',
  height: '80px',
  fontFamily: '-apple-system'
};
const HomeHeader_json_2 = { height: '80px', fontFamily: '-apple-system' };
const HomeHeader_json_3 = { fontSize: '16px' };
export { HomeHeader_json_1 };
export { HomeHeader_json_2 };
export { HomeHeader_json_3 };

const DividerInBox_json = {
  width: '100%',
  paddingTop: '8px',
  paddingBottom: '8px'
};
export { DividerInBox_json };
 