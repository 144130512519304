import PropTypes from 'prop-types';

const objectMap = object => {
  return Object.keys(object).reduce(function(result, key) {
    result[key] = object[key].toString();
    return result;
  }, {});
};

objectMap.propTypes = {
  className: PropTypes.string
};

export default objectMap;
