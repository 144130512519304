import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';

import { path } from 'common/path';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/styles';


import validators from 'common/validators';

import schema from 'common/schema_proof';

import { DropzoneProof, ConfirmProof } from './components';
import { DividerInBox, HomeHeader,SubTitleBar } from 'components';

import { 
  padding_append_proof_1,
  padding_append_proof_2,
  useStyles_appendproof_json
} from 'assets/jss/all_styles';


validate.validators.checked = validators.checked;

const AppendProof = props => {

  const { history } = props;
 
  const classes = makeStyles(useStyles_appendproof_json);

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      proof_type: '',
      commitment_id: '',
      files: []
    },
    touched: {
      proof_type: false,
      commitment_id: false,
      files: false
    },
    errors: {
      proof_type: null,
      commitment_id: null,
      files: null
    }
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleFieldChange = (field, value) => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [field]: value
      },
      touched: {
        ...formState.touched,
        [field]: true
      }
    }));
  };

  const handleProof = event => {
    event.preventDefault();

    let path_file = path + 'back/append_proof';
    const formData = new FormData();

    formData.append('files', formState.values.files[0]);
    formData.append('proof_type', formState.values.proof_type);
    formData.append('commitment_id', props.location.value.id);

    fetch(path_file, {
      method: 'POST',
      credentials: 'include',
      body: formData
    })
      .then(res => {
        console.log('res : ');
        console.log(res);
        history.push('/front/home');
      })
      .catch(err => console.warn(err));
  };

  return (
    <div style={padding_append_proof_1}>
      <HomeHeader
        title="Give a new proof "
        quote_like_text="“Commitment leads to action. Action brings your dream closer.”"
      />
      <SubTitleBar subtitle="Show us what you realised " />
      
      <Paper style={padding_append_proof_2}>
        <form className={classes.form} onSubmit={handleProof}>
          <Box p={2}>

            <DropzoneProof
              formState={formState}
              handleFieldChange={handleFieldChange}
              drop_zone_text="Drag and drop file here or click"
            />

            <DividerInBox />

            <ConfirmProof
              formState={formState}
              button_text="Confirm your commitment"
            />

          </Box>
        </form>
      </Paper>
    </div>
  );
};

AppendProof.propTypes = {
  className: PropTypes.string,
  // classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default withRouter(AppendProof);
