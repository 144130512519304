export async function POST_SERVER(path, values) {
  return fetch(path, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify({
      input_values: values
    })
  })
    .then(r => r.json())
    .then(j => {
      return j;
    })
    .catch(err => console.log(err));
}

// export async function POST_SERVER(path, values) {
//   return fetch(path, {
//     method: 'POST',
//     // credentials: 'include',
//     // mode: 'no-cors',
//     headers : { 
//       'Access-Control-Allow-Origin' : "*",
//       'Content-Type': 'application/json',
//       'Accept': 'application/json'
//      },
//     body: JSON.stringify({
//       input_values: values
//     })
//   })
//     .then(j => {
//       console.log("POST_SERVER : " + j);
//       var j_json = j.json()
//       console.log(j_json);
//       console.log("END POST_SERVER : ");
//       return j_json;
//     })
//     .catch(err => console.log(err));
// }