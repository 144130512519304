import React from 'react';
import PropTypes from 'prop-types';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import DoneIcon from '@material-ui/icons/Done';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import {
  DownloadFile_6,
} from 'assets/jss/all_styles';

const IconStatus = props => {
  console.log("in IconStatus debug :")
  console.log("props.status :")
  console.log(props.status)

  if (props.status === 'success') {
    return (
      <DoneIcon
        className={props.classes.statusIcone}
        fontSize="small"
        style={DownloadFile_6}
      />
    );
  } else if (props.status === 'ongoing') {
    return (
      <AutorenewIcon
        className={props.classes.statusIcone}
        fontSize="small"
        style={DownloadFile_6}
      />
    );
  } else if (props.status === 'failed') {
    return (
      <ClearRoundedIcon
        className={props.classes.statusIcone}
        fontSize="small"
        style={DownloadFile_6}
      />
    );
  } else {
    return (
      <AutorenewIcon
        className={props.classes.statusIcone}
        fontSize="small"
        style={DownloadFile_6}
      />
    );
  }
};

IconStatus.propTypes = {
  className: PropTypes.string
};

export default IconStatus;
