import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import { Typography } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';
import SportsBaseballIcon from '@material-ui/icons/SportsBaseball';
import PhoneRoundedIcon from '@material-ui/icons/PhoneRounded';
import { SmallArea,MenuItemWithIcon } from 'components'

const ThemeBlock = props => {

  return (
    <SmallArea alias={'Theme '} classes={props.classes}>
      <Select
        margin="dense"
        size="normal"
        variant="outlined"
        value={props.values.theme}
        name="theme"
        onChange={event => props.handleFieldChange('theme', event.target.value)}
        style={{ marginTop: '4px', marginBottom: '4px' }}>
        <MenuItem value={'Education'}>
          <MenuItemWithIcon
            value={'Education'}
            icone={<MenuBookIcon fontSize="small" />}
          />
        </MenuItem>
        <MenuItem value={'Sport'}>
          <MenuItemWithIcon
            value={'Sport'}
            icone={<SportsBaseballIcon fontSize="small" />}
          />
        </MenuItem>
        <MenuItem value={'Life'}>
          <MenuItemWithIcon
            value={'Life'}
            icone={<EmojiObjectsIcon fontSize="small" />}
          />
        </MenuItem>
        <MenuItem value={'Game'}>
          <MenuItemWithIcon
            value={'Game'}
            icone={<SportsEsportsIcon fontSize="small" />}
          />
        </MenuItem>
        <MenuItem value={'Contact'}>
          <MenuItemWithIcon
            value={'Contact'}
            icone={<PhoneRoundedIcon fontSize="small" />}
          />
        </MenuItem>
        <MenuItem value={'Work'}>
          <MenuItemWithIcon
            value={'Work'}
            icone={<WorkOutlineIcon fontSize="small" />}
          />
        </MenuItem>
        <MenuItem value={'Other'}>
          <MenuItemWithIcon
            value={'Other'}
            icone={<HelpOutlineIcon fontSize="small" />}
          />
        </MenuItem>
      </Select>
      {props.showThemeError && (
        <Typography
          // className={classes.fieldError}
          variant="body2">
          {props.errors.theme[0]}
        </Typography>
      )}
    </SmallArea>
  );
};

ThemeBlock.propTypes = {
  className: PropTypes.string
};

export default ThemeBlock;
