import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const HelmetMeta = props => {
  return (
    <Helmet>
      <title>About us Title</title>
      <meta
        property="og:url"
        content="https://little-parrot-19.localtunnel.me"
      />
      <meta property="og:title" content="Welcome to Passup" />
      <meta
        property="og:description"
        content="A URL with no session id or extraneous parameters. All shares on Facebook will use this as the identifying URL for this article."
      />
      <meta
        property="og:image"
        content="https://external-preview.redd.it/QB5Nv2dee5NmtpgFOxdjBrfp4MitMx_7OPoYVOLceVk.jpg?width=960&crop=smart&auto=webp&s=1fb548e43b8e5fe9b2fd7ba26af6da4221efcddb"
      />
      <meta property="og:image:type" content="image/png" />
      <meta property="og:type" content="Free Web" />
      <meta property="fb:app_id" content="12345678900" />
      <meta property="article:author" content="Passup" />
      <meta property="article:publisher" content="Passup trioangle" />
      <meta
        property="og:image:secure_url"
        content="https://external-preview.redd.it/QB5Nv2dee5NmtpgFOxdjBrfp4MitMx_7OPoYVOLceVk.jpg?width=960&crop=smart&auto=webp&s=1fb548e43b8e5fe9b2fd7ba26af6da4221efcddb"
      />
      <meta property="og:image:width" content="400" />
      <meta property="og:image:height" content="300" />
    </Helmet>
  );
};
HelmetMeta.propTypes = {
  className: PropTypes.string
};

export default HelmetMeta;
