import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import { Button } from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import {
  DownloadFile_4,
  DownloadFile_5
} from 'assets/jss/all_styles';
import { HomeHeader, DividerInBox, SubTitleBar } from 'components';

const DownloadFile = props => {
  function downloadFile() {
    const link = document.createElement('a');
    link.href = props.url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <Box p={1} flexGrow={0} display="flex" alignSelf="center">
      <Button
        disableRipple={true}
        variant="contained"
        style={DownloadFile_4}
        component="span"
        startIcon={<CloudDownloadIcon style={DownloadFile_5} />}
        onClick={downloadFile}>
        Explain by file
      </Button>
    </Box>
  );
};

DownloadFile.propTypes = {
  className: PropTypes.string
};

export default DownloadFile;
