import * as React from 'react';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Fade from '@mui/material/Fade';

const TransitionAlerts = props => {
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setOpen(props.show_the_alert);
  }, [props.show_the_alert,props.message_to_show]);

  console.log('open');
  console.log(open);
  return (
    <Box sx={{ width: '100%' }}>
      <Fade in={open}>
        <Alert
        icon={false}
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 0 }}>
          {props.message_to_show}
        </Alert>
      </Fade>
    </Box>
  );
};

TransitionAlerts.propTypes = {
  className: PropTypes.string
};

export default TransitionAlerts;