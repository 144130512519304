import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { RouteWithLayout } from './components';
import {
  Main as MainLayout,
  Minimal as MinimalLayout,
  Empty as EmptyLayout
} from './layouts';
import SignInUp from './views/SignInUp';
import Admin from './views/Admin';
import Home from './views/Home';
import ShowCommitment from './views/ShowCommitment';
import Proof from './views/Proof';
import AppendProof from './views/AppendProof';
import AppendCommitment from './views/AppendCommitment';
import Payment from './views/Payment';
import Profil from './views/Profil';
import ShowShareImage from './views/ShowShareImage';
import Conditions from './views/Conditions';


// import LandingPage from './views/LandingPage';

import LandingPage from './views/LandingPage';

import  NotFound from './views/NotFound';

const Routes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to="/front/landing_page" />

      <RouteWithLayout
        component={LandingPage}
        exact
        layout={EmptyLayout}
        path="/front/landing_page"
      />
      <RouteWithLayout
        component={ShowShareImage}
        exact
        layout={EmptyLayout}
        path="/front/show_share_image"
      />
      <RouteWithLayout
        component={Profil}
        exact
        layout={MainLayout}
        path="/front/profile"
      />
      <RouteWithLayout
        component={Payment}
        exact
        layout={MainLayout}
        path="/front/payment"
      />

      <RouteWithLayout
        component={Proof}
        exact
        layout={MainLayout}
        path="/front/proof"
      />

      <RouteWithLayout
        component={ShowCommitment}
        exact
        layout={MainLayout}
        path="/front/show_commitment"
      />
      <RouteWithLayout
        component={Conditions}
        exact
        layout={MainLayout}
        path="/front/conditions"
      />


      <RouteWithLayout
        component={AppendCommitment}
        exact
        layout={MainLayout}
        path="/front/append_commitment"
      />
      <RouteWithLayout
        component={AppendProof}
        exact
        layout={MainLayout}
        path="/front/append_proof"
      />
      <RouteWithLayout
        component={Home}
        exact
        layout={MainLayout}
        path="/front/home"
      />
      <RouteWithLayout
        component={Admin}
        exact
        layout={MainLayout}
        path="/front/admin"
      />
      <RouteWithLayout
        component={SignInUp}
        exact
        layout={EmptyLayout}
        path="/front/sign_in"
      />
      <RouteWithLayout
        component={SignInUp}
        exact
        layout={EmptyLayout}
        path="/front/sign_up"
      />
      <RouteWithLayout
        component={NotFound}
        exact
        layout={MainLayout}
        path="/front/not-found"
      />
      {/* <RouteWithLayout
        component={Conditions}
        exact
        layout={MainLayout}
        path="/front/conditions"
      />
      <RouteWithLayout
        component={Privacy}
        exact
        layout={MainLayout}
        path="/front/privacy"
      /> */}
      <Redirect to="/front/not-found" />
    </Switch>
  );
};

export default Routes;
