import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import { Button } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';

import sunflower from 'assets/img/icons8-sunflower-50.png';
import {
  Profile_json_2,
  Profile_json_3,
  Profile_json_6,
  Profile_json_7
} from 'assets/jss/all_styles';

const ButtonToPayment = props => {
let { open, point } = props;
const [number_in_badge, setnumber_in_badge] = useState( parseInt(point, 10));



useEffect(
  () => { 
    setnumber_in_badge(parseInt(point, 10))   
  }
  ,
  [props.point]
);

return (
  <Link to="/front/payment">
    <Box p={0} display="flex" alignSelf="center" flexGrow={1}>
      <Box
        p={1}
        display="flex"
        flexGrow={0}
        style={Profile_json_2}
        alignSelf="center"
        justifyContent="center">


        <Badge
          overlap="circular"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          color="primary"
          badgeContent={String(number_in_badge)}
          style={Profile_json_3}
          max={999}
          showZero
          >  
          <img
            alt="Sunwill flower"
            width="44px"
            src={sunflower}
            style={Profile_json_6}
          />

        </Badge>



      </Box>

      {open ? (
        <Box p={1} display="flex" alignSelf="center">
          <Button
            fullWidth
            disabled={false}
            variant="outlined"
            style={Profile_json_7}>
            Get more Sunwill flowers
          </Button>
        </Box>
      ) : (
        <Box p={0} display="flex" alignSelf="center"></Box>
      )}
    </Box>
  </Link>
);
};

ButtonToPayment.propTypes = {
className: PropTypes.string
};

export default ButtonToPayment;
