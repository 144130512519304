import React, { useState, useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Button,
  IconButton,
  TextField,
  Link,
  Typography
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// import RadioGroup from "@material-ui/core/RadioGroup";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Radio from "@material-ui/core/Radio";
// import FormLabel from "@material-ui/core/FormLabel";
// import FormControl from "@material-ui/core/FormControl";
// import Switch from "@material-ui/core/Switch";
// import Paper from "@material-ui/core/Paper";
// import List from "@material-ui/core/List";
// import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from "@material-ui/core/ListItemIcon";
// import PeopleIcon from '@material-ui/icons/People';
// import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
// import ListItemText from '@material-ui/core/ListItemText';
// import ListSubheader from "@material-ui/core/ListSubheader";
// import HowToRegIcon from '@material-ui/icons/HowToReg';

import { Facebook as FacebookIcon, Google as GoogleIcon } from 'icons';

// import { GET_SERVER, POST_SERVER, path } from '../../common';
import { GET_SERVER } from 'common/get';
import { POST_SERVER } from 'common/post';
import { path } from 'common/path';
import { EditorFormatColorReset } from 'material-ui/svg-icons';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import PostAddIcon from '@material-ui/icons/PostAdd';

import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { Divider, Drawer } from '@material-ui/core';
import { BigArea, SmallArea } from 'components';
import { useStyles_appendproof_json } from 'assets/jss/all_styles';
import { schema } from 'common/schema_proof';


const DropzoneProof = props => {
    const classes = makeStyles(useStyles_appendproof_json);
  
    const type_dictionary = {
      Image: ['png', 'jpeg', 'jpg'],
      Video: ['mp4', 'avi'],
      Text: ['txt'],
      Audio: ['mp3'],
      Document: ['pdf']
    };
  
    const guessTypeFile = () => {
      // console.log("formState.values.files" + formState.values.files[0].name)
  
      if (props.formState.values.files.length !==0) {
        var splits = props.formState.values.files[0].name.split('.');
        var last_element = splits[splits.length - 1];
        // console.log('extention :  ' + last_element);
  
        for (var key in type_dictionary) {
          if (type_dictionary[key].includes(last_element)) {
            props.handleFieldChange('proof_type', key);
          }
        }
      }
    };
  
    const handleFile = (field, value) => {
      props.handleFieldChange(field, value);
      guessTypeFile();
    };
    return (
      <BigArea alias={'Proof'} classes={classes}>
        <SmallArea alias={'Upload your proof '} classes={classes}>
          <Box p={1}>
            <DropzoneArea
              onChange={files => {
                handleFile('files', files);
              }}
              onDrop={files => {
                handleFile('files', files);
              }}
              // onChange={(files) => console.log('Files:', files)}
              filesLimit={1}
              dropzoneText={props.drop_zone_text}
              maxFileSize={20000000}></DropzoneArea>
          </Box>
        </SmallArea>
      </BigArea>
    );
  };


  DropzoneProof.propTypes = {
    className: PropTypes.string
  };
  
  export default DropzoneProof;