import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
// import { GET_SERVER, POST_SERVER, path } from '../../common';
import { GET_SERVER } from 'common/get';
import { POST_SERVER } from 'common/post';
import { path } from 'common/path';
// Material helpers
import { ThemeProvider, withStyles } from '@material-ui/core';
// Externals
//import PropTypes from 'prop-types';
import compose from 'recompose/compose';
// Form validation schema
import schema from 'common/schema_commitment';
// Externals
// Shared utilities
import validators from 'common/validators';
import validate from 'validate.js';
import _ from 'underscore';
import {
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  // IconButton,
  TextField,
  Typography
} from '@material-ui/core';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import PeopleIcon from '@material-ui/icons/People';
import SchoolIcon from '@material-ui/icons/School';
import DateFnsUtils from '@date-io/date-fns';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { DropzoneArea } from 'material-ui-dropzone';
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
  KeyboardTimePicker,
  DateTimePicker,
  KeyboardDatePicker
} from '@material-ui/pickers';
import { Dashboard as DashboardLayout } from 'layouts';
import { green } from '@material-ui/core/colors';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import PostAddIcon from '@material-ui/icons/PostAdd';
// import { makeStyles } from '@material-ui/core/styles';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';
import SportsBaseballIcon from '@material-ui/icons/SportsBaseball';
import MicIcon from '@material-ui/icons/Mic';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { FileInput, SVGIcon } from 'react-md';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { Divider, Drawer } from '@material-ui/core';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import PhoneRoundedIcon from '@material-ui/icons/PhoneRounded';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import sunflower from 'assets/img/icons8-sunflower-50.png';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Input from '@material-ui/core/Input';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles, createTheme } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import {
  CommitmentInformation,
  ProofInformation,
  TermInformation,
  MotivationInformation,
  CreateCommitment
} from './components';
import { HomeHeader,DividerInBox, SubTitleBar,TransitionAlerts } from 'components';
import { styles } from 'assets/jss/all_styles';
import { FacebookProvider, Share } from 'react-facebook';

validate.validators.checked = validators.checked;

class AppendCommitement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      radio: 'Text',
      values: {
        title: '',
        description: '',
        theme: 'Sport',
        proof_type: 'Image',

        // end_date:"2020/08/01 22:15",
        // end_date:this.add_weeks(new Date(), 1).toString(),

        end_date: new Date(
          new Date().setDate(new Date().getDate() + 1 * 7)
        ).toString(),
        data_text: '',
        data_files: [],

        number_sunflowers_in_user_basket: 0,
        use_sunflowers: false,
        number_sunflowers: 10,
        use_social_share: false,
        image_to_share_url: '',
        nbr_sunflowers_used_in_commitment_in_progress:10,
        show_the_alert: false,
      },
      touched: {
        theme: false,
        title: false,
        description: false,
        proof_type: false,
        data_text: false,
        data_files: false,
        number_sunflowers_in_user_basket: false,
        use_sunflowers: false,
        number_sunflowers: false,
        use_social_share: false,
        image_to_share_url: false,
        nbr_sunflowers_used_in_commitment_in_progress: false
      },
      errors: {
        theme: null,
        title: null,
        description: null,
        proof_type: null,
        data_text: null,
        data_files: null,
        number_sunflowers_in_user_basket: null,
        use_sunflowers: null,
        number_sunflowers: null,
        use_social_share: null,
        image_to_share_url: null,
        nbr_sunflowers_used_in_commitment_in_progress: false
      },
      //bind ici les fonctions
      isValid: false,
      isLoading: false,
      submitError: null
    };
    this.handleNumberOfSunflowersForUser();
  }

  handleNumberOfSunflowersForUser = async () => {
    // console.log('this.state : ' + this.state);
    // console.log(this.state);

    let profile_data_path = path + 'back/profile_data';
    const var_from_POST_SERVER = await GET_SERVER(profile_data_path);
    const profile_data = var_from_POST_SERVER['profile_data'];
    // console.log('profile_data point  : ' + profile_data['point']);
    // console.log(profile_data['point']);
    const newState = this.state;
    newState.values.number_sunflowers_in_user_basket = parseInt(
      profile_data['point'],
      10
    );
    newState.values.nbr_sunflowers_used_in_commitment_in_progress = parseInt(
      profile_data['nbr_sunflowers_used_in_commitment_in_progress'],
      10
    );
    this.setState(newState);
  };
  validate_special_case = (errors, values) => {
    // console.log('number_sunflowers_in_user_basket');
    // console.log(values.number_sunflowers_in_user_basket);
    // console.log(typeof values.number_sunflowers_in_user_basket);

    // console.log('values.number_sunflowers');
    // console.log(values.number_sunflowers);
    // console.log(typeof values.number_sunflowers);

    // console.log(typeof errors !== 'undefined');
    // console.log(parseInt(values.number_sunflowers));
    // console.log(parseInt(values.number_sunflowers_in_user_basket));
    // console.log('condi');
    // console.log(
    //   parseInt(values.number_sunflowers) >
    //     parseInt(values.number_sunflowers_in_user_basket)
    // );
    // console.log(values.use_sunflowers);
    // console.log(errors);
    // console.log('end');

    console.log( "parseInt(values.number_sunflowers)" + parseInt(values.number_sunflowers) );
    console.log( "parseInt(values.number_sunflowers_in_user_basket)" + parseInt(values.number_sunflowers_in_user_basket) );
    console.log( "parseInt(values.nbr_sunflowers_used_in_commitment_in_progress)" + parseInt(values.nbr_sunflowers_used_in_commitment_in_progress) );

    if ( parseInt(values.number_sunflowers) > ( parseInt(values.number_sunflowers_in_user_basket)-parseInt(values.nbr_sunflowers_used_in_commitment_in_progress) ) 
    && values.use_sunflowers
    ) {
      if (typeof errors === 'undefined') {
        errors = {};
      }

      const newState = { ...this.state };
      newState.values.show_the_alert = true;
      this.setState(newState);

      errors.number_sunflowers = [
        'You dont have enaugh sunflowers in your sunflower basket'
      ];

      console.log('in errors number_sunflowers');
      return errors;
    } else {

      const newState = { ...this.state };
      newState.values.show_the_alert = false;
      this.setState(newState);

      return errors;
    }
  };

  validateForm = _.debounce(() => {
    const { values } = this.state;
    const newState = { ...this.state };

    // console.log('debounce1');
    // console.log(newState.errors);

    const errors_before_special_case = validate(values, schema);
    const errors = this.validate_special_case(
      errors_before_special_case,
      values
    );
    // console.log('debounce2');
    // console.log(newState.errors);
    // console.log('debounce2.5');
    // console.log(errors || {});

    newState.errors = errors || {};

    // console.log('debounce3');
    // console.log(newState.errors);
    // console.log('debounce3.5');
    // console.log(errors);
    // console.log('debounce3.75');
    // console.log(errors ? false : true);
    // console.log(typeof errors);
    // console.log(errors);
    // console.log('debounce3.85');
    // console.log(errors_before_special_case ? false : true);
    // console.log(typeof errors_before_special_case);
    // console.log(errors_before_special_case);

    // Object.keys(dictionary).length
    newState.isValid = errors ? false : true;

    // console.log('debounce4');
    // console.log(newState.errors);
    // console.log('debounce4.5');
    // console.log(newState);

    this.setState(newState);

    // console.log('is valide ' + this.state.isValid);
    // console.log(this.state.errors);
  }, 100);

  handleOnChangeRadio = value => {
    const newState = { ...this.state };
    newState.radio = value;
    this.setState(newState);
  };

  handleDateChange = value => {
    const newState = { ...this.state };
    newState.values['end_date'] = value;
    // newState.values['end_date'] = value.toLocaleDateString();
    this.setState(newState);
    // const dt=this.state.values.end_date
    // const date=this.state.values.end_date.setDate( date.getDate() + 3)
    // console.log('date' + this.state.values.end_date);
    // console.log("plus weeks"+this.add_weeks(dt, 10).toString())
  };

  fileSelectedHandler = files => {
    // console.log('fileSelectedHandler start ');
    // console.log(files);
    // console.log('fileSelectedHandler end');
    this.handleFieldChange('data_files', files);
  };

  handleFieldChange = async (field, value) => {
    const newState = { ...this.state };

    console.log('field : ' + field);
    console.log('value : ' + value);
    console.log(field);
    console.log(value);
    console.log('end field value handleFieldChange');

    newState.submitError = null;
    newState.touched[field] = false;
    newState.values[field] = value;

    await this.setState(newState, this.validateForm);
  };

  handleCommit = async event => {
    event.preventDefault();

    const { history } = this.props;
    let path_file = path + 'back/append_commitment';
    const formData = new FormData();
    console.log("#################################################")
    console.log('this.state.values.end_date');
    console.log(this.state.values.end_date);

    const end_date_with_date = new Date(this.state.values.end_date);
    var date_in_iso_format = end_date_with_date.toISOString();
    console.log("date_in_iso_format : ");
    console.log(date_in_iso_format);
    console.log("#################################################")

    formData.append('files', this.state.values.data_files[0]);
    formData.append('proof_type', this.state.values.proof_type);
    formData.append('theme', this.state.values.theme);
    formData.append('title', this.state.values.title);
    formData.append('end_date', date_in_iso_format);
    formData.append('description', this.state.values.description);
    formData.append('data_text', this.state.values.data_text);

    formData.append('use_sunflowers', this.state.values.use_sunflowers);
    formData.append('number_sunflowers', this.state.values.number_sunflowers);
    formData.append('use_social_share', this.state.values.use_social_share);
    formData.append('image_to_share_url', this.state.values.image_to_share_url);
    

    fetch(path_file, {
      method: 'POST',
      credentials: 'include',
      body: formData
    })
      .then(res => {
        console.log('res : ');
        console.log(res);
        history.push('/front/home');
      })
      .catch(err => console.warn(err));
  };

  render() {
    const { classes, ...rest } = this.props;
    const { history } = this.props;

    const { date, format, mode, inputFormat } = this.state;
    const {
      values,
      touched,
      errors,
      isValid,
      submitError,
      isLoading
    } = this.state;
    const showThemeError =
      touched.theme && errors.theme ? errors.theme[0] : false;
    const showTitleError =
      touched.title && errors.title ? errors.title[0] : false;
    const showDescriptionError =
      touched.description && errors.description ? errors.description[0] : false;
    const showProofTypeError =
      touched.description && errors.description ? errors.description[0] : false;
    const showNumberSunflowersError =
      touched.number_sunflowers && errors.number_sunflowers
        ? errors.number_sunflowers[0]
        : false;
    var radio_variable_to_test = this.state.radio;

    // console.log('radio_variable_to_test : ' + radio_variable_to_test);

    return (
      <div>
        <div style={{ padding: '30px' }}>
          <HomeHeader
            title="Let us know what you want to do "
            quote_like_text="“Commitment leads to action. Action brings your dream closer.”"
          />
          <SubTitleBar subtitle="Let us know about your commitment " />

          <Paper style={{ maxWidth: '738px' }}>
            <Box p={2}>
              <TransitionAlerts
                show_the_alert={values.show_the_alert}
                message_to_show={errors.number_sunflowers}
              /> 
              <CommitmentInformation
                classes={classes}
                values={values}
                errors={errors}
                showThemeError={showThemeError}
                showDescriptionError={showDescriptionError}
                showTitleError={showTitleError}
                handleFieldChange={this.handleFieldChange}
              />
              {/* <DividerInBox />
              <InitialInformation
                classes={classes}
                state={this.state}
                handleFieldChange={this.handleFieldChange}
              /> */}
              {/* <DividerInBox />
              <ProofInformation
                classes={classes}
                values={values}
                errors={errors}
                showProofTypeError={showProofTypeError}
                handleFieldChange={this.handleFieldChange}
              /> */}
              <DividerInBox />
              <TermInformation
                classes={classes}
                values={values}
                handleFieldChange={this.handleFieldChange}
              />
              <DividerInBox />
              <MotivationInformation
                classes={classes}
                values={values}
                errors={errors}
                showNumberSunflowersError={showNumberSunflowersError}
                handleFieldChange={this.handleFieldChange}
              />

              <FacebookProvider appId="777721982806390">
                <CreateCommitment
                  classes={classes}
                  isValid={isValid}
                  values={values}
                  handleCommit={this.handleCommit}
                />
              </FacebookProvider>
            </Box>
          </Paper>
        </div>
      </div>
    );
  }
}

AppendCommitement.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default compose(withRouter, withStyles(styles))(AppendCommitement);
