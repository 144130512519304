import React from 'react';
import PropTypes from 'prop-types';

import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';

import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { ImageContainer } from 'components';

const ScreenIntroduction = props => {
  const { classes } = props;
  return (
    <div className={classes.container}>
      <GridContainer alignItems="center">
        <Grid item md={5} sm={12} xs={12}>
          <Box display="flex" justifyContent="center" m={1} p={1}>
            <Box p={1}>
              <Box display="flex" justifyContent="center">
                <h1 className={classes.title}>{props.title}</h1>
              </Box>
              <Box display="flex" justifyContent="center">
                <h4>
                  {props.subtitle}
                  <br />
                  <br />
                </h4>
                <br />
                <br />
                <br />
              </Box>
              <Box display="flex" justifyContent="center">
                <p>{props.text_introduction}</p>
              </Box>
              <br />
              <Box display="flex" justifyContent="center">
                <Link
                  className={classes.navLink}
                  color="transparent"
                  to="/front/sign_in">
                  <Button color="success" rel="noopener noreferrer" size="sm">
                    <i className="fas fa-play" />
                    {props.button_text}
                  </Button>
                </Link>
              </Box>
            </Box>
          </Box>
        </Grid>
        <GridItem  item  md={7} sm={12} xs={12}>
          <ImageContainer
            className={classes.mainimage}
            path_or_url_of_image={props.screen_image}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
};
ScreenIntroduction.propTypes = {
  className: PropTypes.string
};

export default ScreenIntroduction;
