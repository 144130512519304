import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import Box from '@material-ui/core/Box';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import sunflower from 'assets/img/icons8-sunflower-50.png';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import {
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  // IconButton,
  TextField,
  Typography
} from '@material-ui/core';
import { SmallArea } from 'components';

import { 
  number_sunflowers_input 
} from 'assets/jss/all_styles';

const NumberSunflowers = props => {
  return (
    <SmallArea alias={'Number of Sunflowers  *'} classes={props.classes}>
      <Box display="flex">
        <Box p={0}>
          {/* className={clsx(classes.margin, classes.textField)}  */}
          <FormControl variant="outlined">
            {/* <InputLabel htmlFor="outlined-adornment-password">Number of Sunflowers</InputLabel> */}

            <OutlinedInput
              id="outlined-adornment-password"
              type={'text'}
              // onChange={handleChange('password')}
              // endAdornment={
              //   <InputAdornment position="end">
              //     <IconButton
              //       aria-label="toggle password visibility"
              //       onClick={handleClickShowPassword}
              //       onMouseDown={handleMouseDownPassword}
              //       edge="end"
              //     >
              //       {values.showPassword ? <Visibility /> : <VisibilityOff />}
              //     </IconButton>
              //   </InputAdornment>
              // }
              labelWidth={70}
              fullWidth
              size="small"
              // className={classes.textField}
              // subheader={<ListSubheader>Username</ListSubheader>}
              // label={"Number of Sunflowers"}
              name="number_sunflowers"
              onChange={event => {
                if (event.target.value >= 0) {
                  props.handleFieldChange(
                    'number_sunflowers',
                    event.target.value
                  );
                }
              }}
              value={props.values.number_sunflowers}
              variant="outlined"
              margin="dense"
              style={number_sunflowers_input}
              // endAdornment={
              // 	<InputAdornment position="end">
              // 	  <Visibility />
              // 	</InputAdornment>
              //   }
              endAdornment={
                <InputAdornment position="end">
                  <img alt="Sunflowers" width="34px" src={sunflower} />
                </InputAdornment>
              }
            />
          </FormControl>
          {/* <InputLabel htmlFor="outlined-adornment-password">Number of Sunflowers</InputLabel> */}

          {/* <OutlinedInput
                        id="outlined-adornment-password"
                        type={values.showPassword ? 'text' : 'password'}
                        value={values.password}
                        onChange={handleChange('password')}
                        endAdornment={
                            <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {values.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                            </InputAdornment>
                        }
                        labelWidth={70}
                        /> */}

          {/* <OutlinedInput
                            fullWidth
                            id="outlined-adornment-password"
                            size="small"
                            // className={classes.textField}
                            // subheader={<ListSubheader>Username</ListSubheader>}
                            label={"Number of Sunflowers"}
                            name="number_of_points"
                            onChange={event =>
                                this.handleFieldChange("number_of_points", event.target.value)
                            }
                            value={values.number_of_points}
                            type="text"
                            variant="outlined"
                            margin="dense"
                            style={number_sunflowers_input}
                            // endAdornment={
                            // 	<InputAdornment position="end">
                            // 	  <Visibility /> 
                            // 	</InputAdornment>
                            //   }
                            endAdornment={
                                <InputAdornment position="start">
                                    <img
                                        alt="Sunflowers"
                                        width="24px"
                                        src={sunflower}
                                    />
                                </InputAdornment>
                              }
                        // className={classes.textfieldformat}
                        /> */}
        </Box>
        {/* <Box p={1}>
                        <img
                            alt="Sunflowers"
                            width="34px"
                            src={sunflower}
                        />
                    </Box> */}
      </Box>

      {props.showNumberSunflowersError && (
        <Typography
          // className={classes.fieldError}
          variant="body2">
          {props.errors.number_sunflowers[0]}
        </Typography>
      )}
    </SmallArea>
  );
};

NumberSunflowers.propTypes = {
  className: PropTypes.string
};

export default NumberSunflowers;
