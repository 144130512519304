import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import Box from '@material-ui/core/Box';

export const MenuItemWithIcon = props => {
  return (
    <Box display="flex">
      <Box style={{ paddingRight: '4px' }}>{props.icone}</Box>
      <Box alignSelf="center">{props.value}</Box>
    </Box>
  );
};


export default MenuItemWithIcon